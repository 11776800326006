<template>
  <nv-tab-page>
    <template slot="toolbar">
      <application-add-menu
        v-if="isActiveOwner"
        :available="['template', 'custom', 'bulk']"
        @selected="appTypeSelected"
      />
      <v-spacer />

      <v-btn-toggle v-model="settings.product_type" class="ml-5 mr-1">
        <v-btn
          v-for="item in enumProductTypes"
          :key="item.value"
          :value="item.value"
          text
          small
          class="mx-0"
        >
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>

      <nv-table-search v-model="search" class="ml-4 mr-0 hidden-xs-only" />
    </template>
    <template slot="filters">
      <nv-table-filters :value="formattedFilters" @clear="$refs['filter-sheet'].clearFilters()">
        <filter-sheet
          ref="filter-sheet"
          :product-type="settings.product_type"
          @changed="setTableFilters"
        />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="applicationPath(item.id)">
              {{ item.name }}
              <br />
              <span class="text-caption">{{ item.description }}</span>
            </router-link>
          </td>
          <td>{{ item.id }}</td>
          <td>
            <client-show :value="item" display-type="table" />
          </td>
          <td>
            {{ item.operating_system | enumLabel(enumOperatingSystems) }}
          </td>
          <td>
            {{ item.implementation_type | enumLabel(enumImplementationTypes) }}
          </td>
          <td>{{ item.owner_type | enumLabel(enumOwnerTypes) }}</td>
          <td>{{ item.app_type | enumLabel(enumAppTypes) }}</td>
          <td>{{ item.syndicated | enumLabel(syndicated) }}</td>
          <td>
            {{ item.deployment_type | enumLabel(enumDeploymentTypes) }}
          </td>
          <td>
            {{ item.cbc_key }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <new-template-form v-model="dialog.template" :owner-id="ownerId" @saved="tableFetch" />

    <new-custom-form
      v-model="dialog.custom"
      :owner-id="ownerId"
      :product-type="productType"
      @saved="tableFetch"
    />

    <new-bulk-form v-if="ownerId" v-model="dialog.bulk" :owner-id="ownerId" @saved="tableFetch" />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/dog';
import { formatFilters } from '@/helpers/filters';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import NewCustomForm from '@/components/applications/NewCustomForm.vue';
import NewTemplateForm from '@/components/applications/NewTemplateForm.vue';
import NewBulkForm from '@/components/applications/NewBulkForm.vue';
import FilterSheet from '@/components/applications/FilterSheet.vue';
import ApplicationAddMenu from '@/components/ApplicationAddMenu.vue';
import ClientShow from '@/components/applications/ClientShow.vue';

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'ID', sortable: false, value: 'id' },
  { text: 'Client ID/Sub-brand', sortable: false, value: 'client_id' },
  { text: 'OS', sortable: false, value: 'operating_system' },
  { text: 'Implementation', sortable: false, value: 'implementation_type' },
  { text: 'Ownership', sortable: false, value: 'owner_type' },
  { text: 'Integration Type', sortable: false, value: 'app_type' },
  { text: 'Syndication', sortable: false, value: 'syndicated' },
  { text: 'Deployment', sortable: false, value: 'deployment_type' },
  { text: 'CBC Key', sortable: false, value: 'cbc_key' },
];

export default {
  components: {
    NewCustomForm,
    NewTemplateForm,
    NewBulkForm,
    FilterSheet,
    ApplicationAddMenu,
    ClientShow,
  },
  mixins: [TableMixin],
  props: {
    ownerId: { type: [Number, String], default: null },
    ownerActive: { type: Boolean, default: true },
    templateId: { type: [Number, String], default: null },
    ddrsId: { type: [Number, String], default: null },
    clientId: { type: [Number, String], default: null },
    parentId: { type: [Number, String], default: null },
  },
  data: () => ({
    headers,
    dialog: {
      template: false,
      custom: false,
      bulk: false,
    },
    enumParamStatuses: [],
    enumOperatingSystems: [],
    enumImplementationTypes: [],
    enumOwnerTypes: [],
    enumAppTypes: [],
    enumDeploymentTypes: [],
    enumProductTypes: [],
    settings: {
      product_type: null,
    },
    productType: 'ownersapps',
    syndicated: [],
    countries: [],
    tableParam: 'apps.dt',
  }),
  computed: {
    isActiveOwner() {
      if (this.ownerId) {
        return this.ownerId && this.ownerActive;
      }
      return false;
    },
    formattedFilters() {
      return formatFilters(this.tableFilters, this.countries);
    },
  },
  watch: {
    'settings.product_type': function() {
      this.tableFetch();
    },
  },
  created() {
    this.enumFetch(HTTP, {
      enumParamStatuses: '/applications/enum/statuses',
      enumOperatingSystems: '/applications/enum/operating_systems',
      enumImplementationTypes: '/applications/enum/implementations',
      enumOwnerTypes: '/applications/enum/owners',
      enumAppTypes: '/applications/enum/types',
      enumDeploymentTypes: '/applications/enum/deployment_types',
      enumProductTypes: '/applications/enum/product_types',
      syndicated: '/applications/enum/syndicated',
      countries: '/countries?per_page=300&filter[status]=active',
    });
  },
  methods: {
    tableFetch() {
      if (this.ownerId) {
        this.paginateTableData(HTTP, `/owners/${this.ownerId}/applications`);
      }
      if (this.templateId) {
        this.paginateTableData(
          HTTP,
          `/applications?filter[application_template_id]=${this.templateId}`,
        );
      }
      if (this.ddrsId) {
        this.paginateTableData(HTTP, `/ddrs_entities/${this.ddrsId}/applications`);
      }
      if (this.clientId) {
        this.paginateTableData(HTTP, `/dcr_clients/${this.clientId}/applications`);
      }
      if (this.parentId) {
        this.paginateTableData(HTTP, `/mv/parents/${this.parentId}/applications`);
      }
    },
    appTypeSelected(type) {
      this.dialog[type] = true;
    },
    applicationPath(applicationId) {
      if (this.ownerId) {
        return {
          name: 'dog.owner.applications.show',
          params: {
            ownerId: this.ownerId,
            applicationId,
          },
        };
      }
      return {
        name: 'dog.applications.show',
        params: {
          applicationId,
        },
      };
    },
    enumFetch,
  },
};
</script>

<style scoped>
table td {
  font-size: 11px !important;
}
.caption {
  font-size: 10px !important;
}
</style>
