<template>
  <v-container>
    <v-row>
      <v-col sm="3" cols="12">
        <span class="text-caption">Status</span>&nbsp;
        <v-btn-toggle v-model="filters.status">
          <v-btn v-for="item in statuses" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-if="deploymentTypes.length" sm="3" cols="12">
        <span class="text-caption">Deployment</span>&nbsp;
        <v-btn-toggle v-model="filters.deployment_type">
          <v-btn v-for="item in deploymentTypes" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col sm="3" cols="12">
        <span class="text-caption">Owner type</span>&nbsp;
        <v-btn-toggle v-model="filters.owner_type">
          <v-btn v-for="item in ownerTypes" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col sm="3" cols="12">
        <span class="text-caption">Syndication</span>&nbsp;
        <v-btn-toggle v-model="filters.syndicated">
          <v-btn v-for="item in syndicated" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="mt-12" justify="space-between">
      <v-col sm="3" cols="12">
        <v-select
          v-model="filters.operating_system"
          :items="operatingSystems"
          clearable
          label="Operating System"
        />
      </v-col>
      <v-col sm="3" cols="12">
        <v-select v-model="filters.jic_code" :items="jicCodes" clearable label="JIC Code" />
      </v-col>
      <v-col sm="3" cols="12">
        <v-autocomplete
          v-model="filters.country_code"
          :items="countries"
          item-text="name"
          item-value="code"
          clearable
          label="Country"
        />
      </v-col>

      <v-col sm="3" cols="12">
        <v-select
          ref="products"
          v-model="filters.product"
          :items="products"
          item-text="name"
          item-value="code"
          label="Product"
          clearable
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  props: {
    productType: { type: String, default: null },
    listType: { type: String, default: 'dog/applications' },
  },
  data: () => ({
    settings: {
      product_type: null,
    },
    countries: [],
    jicCodes: [],
    allProducts: [],
    statuses: [],
    operatingSystems: [],
    implementationTypes: [],
    ownerTypes: [],
    appTypes: [],
    syndicated: [],
    productTypes: [],
    deploymentTypes: [],
    enumFetch,
    filtersParam: 'apps.ft',
  }),
  computed: {
    products() {
      if (this.productType) {
        return this.allProducts.filter(x => x.type === this.productType);
      }
      return this.allProducts;
    },
  },
  created() {
    this.enumFetch(HTTP, {
      operatingSystems: '/applications/enum/operating_systems',
      implementationTypes: '/applications/enum/implementations',
      ownerTypes: '/applications/enum/owners',
      appTypes: '/applications/enum/types',
      productTypes: '/applications/enum/product_types',
      countries: '/countries',
      statuses: '/applications/enum/statuses',
      jicCodes: '/applications/enum/jic_codes',
      syndicated: '/applications/enum/syndicated',
      allProducts: '/applications/enum/products',
    });
  },
};
</script>
