<template>
  <nv-tab-page>
    <template slot="toolbar">
      <nv-btn v-if="canEdit()" type="add" data-cy="add-parameter" @click="newForm.show = true" />
      <new-parameter-form
        v-model="newForm.show"
        :application-id="applicationId"
        :template-id="templateId"
        @saved="tableFetch"
      />

      <v-spacer />
      <nv-table-search v-model="search" class="ml-4" />
    </template>

    <template slot="filters">
      <nv-table-filters :value="tableFilters" @clear="$refs['filter-sheet'].clearFilters()">
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.master_parameter.name }}</td>
          <td>{{ item.value || item.master_parameter.default_value }}</td>
          <td>{{ item.encoding || item.master_parameter.encoding }}</td>
          <td>{{ item.master_parameter.description }}</td>
          <td>{{ item.master_parameter.parameter_type | enumLabel(enumParameterTypes) }}</td>
          <td>
            <product-chip-group v-model="item.master_parameter.products" />
          </td>
          <td>{{ item.status | enumLabel(enumStatuses) }}</td>
          <td class="text-right">
            <v-btn v-if="canEdit()" icon class="param-edit-icon" @click="openEdit(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <edit-form
      v-model="editDialog.show"
      :editing="editDialog.edit"
      :application-id="applicationId"
      :app-count="appCount"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import NewParameterForm from '@/components/parameters/NewForm.vue';
import EditForm from '@/components/parameters/EditForm.vue';
import FilterSheet from '@/components/parameters/FilterSheet.vue';
import ProductChipGroup from '@/components/ProductChipGroup.vue';

const headers = [
  { text: 'Name', align: 'left', sortable: false, value: 'name' },
  { text: 'Value', align: 'left', sortable: false, value: 'value' },
  { text: 'Encoding', align: 'left', sortable: false, value: 'encoding' },
  { text: 'Description', align: 'left', sortable: false, value: 'description' },
  { text: 'Type', align: 'left', sortable: false, value: 'parameter_type' },
  { text: 'Products', align: 'left', sortable: false, value: 'products' },
  { text: 'Status', align: 'left', sortable: false, value: 'status' },
  { align: 'right', sortable: false },
];

export default {
  components: {
    NewParameterForm,
    EditForm,
    FilterSheet,
    ProductChipGroup,
  },
  mixins: [TableMixin],
  props: {
    applicationId: { type: String, default: null },
    templateId: { type: [Number, String], default: null },
    appCount: { type: Number, default: 0 },
    isEditable: { type: Boolean, default: true },
  },
  data: () => ({
    headers,
    filters: {
      status: 'active',
    },
    newForm: { show: false },
    editDialog: { show: false, edit: null },
    enumStatuses: [],
    enumParameterTypes: [],
    tableParam: 'params.dt',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumStatuses: '/parameters/enum/statuses',
      enumParameterTypes: '/parameters/enum/types',
    });
  },
  methods: {
    tableFetch() {
      if (this.applicationId) {
        this.paginateTableData(HTTP, `/applications/${this.applicationId}/parameters`);
      } else if (this.templateId) {
        this.paginateTableData(HTTP, `/applications/templates/${this.templateId}/parameters`);
      }
    },
    openEdit(item) {
      this.editDialog.edit = item;
      this.editDialog.show = true;
    },
    canEdit() {
      if (!this.isEditable) {
        return false;
      }
      // Template Parameter
      if (this.templateId) {
        if (this.appCount === 0) {
          return this.$auth.hasPermissions('dog.application_templates');
        }
        return false;
      }
      // Application Parameter
      if (this.applicationId) {
        return this.$auth.hasPermissions('dog.parameters');
      }
      return false;
    },
    enumFetch,
  },
};
</script>
