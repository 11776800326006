<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-subheader class="pl-0">Products</v-subheader>
    </v-col>
    <v-col cols="12" sm="3" md="2">
      <v-checkbox
        ref="dtvr"
        v-model="products"
        :disabled="canEdit('dtvr')"
        label="DTVR"
        title="Digital TV Ratings"
        value="dtvr"
        hide-details
        @change="$emit('input', products)"
      />
    </v-col>
    <v-col cols="12" sm="3" md="2">
      <v-checkbox
        ref="dcr_video"
        v-model="products"
        :disabled="canEdit('dcr_video')"
        label="DCR Video"
        title="Digital Content Ratings Video"
        value="dcr_video"
        hide-details
        @change="$emit('input', products)"
      />
    </v-col>
    <v-col cols="12" sm="3" md="2">
      <v-checkbox
        ref="dcr_static"
        v-model="products"
        :disabled="canEdit('dcr_static')"
        label="DCR Static"
        title="Digital Content Ratings Static"
        value="dcr_static"
        hide-details
        @change="$emit('input', products)"
      />
    </v-col>
    <v-col cols="12" sm="3" md="2">
      <v-checkbox
        ref="dtvr_sm"
        v-model="products"
        :disabled="canEdit('dtvr_sm')"
        label="DTVR Subminute"
        title="MTVR subminute enabled flag"
        value="dtvr_sm"
        hide-details
        @change="$emit('input', products)"
      />
    </v-col>
    <v-col cols="12" sm="3" md="2">
      <v-checkbox
        ref="dar"
        v-model="products"
        :disabled="canEdit('dar')"
        label="DAR"
        title="Digital Ad Ratings"
        value="dar"
        data-cy="dar-product-checkbox"
        hide-details
        @change="$emit('input', products)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: null },
    disabled: { type: Boolean, default: false },
    currentProducts: { type: Array, default: null },
    appTemplateId: { type: Number, default: null },
  },
  data: () => ({
    products: [],
  }),
  watch: {
    value() {
      if (this.value) {
        this.products = this.value;
      }
    },
  },
  created() {
    this.products = this.value ? this.value : [];
  },
  methods: {
    canEdit(product) {
      if (this.disabled) return true;
      if (!this.currentProducts) return false;
      if (!this.appTemplateId) return false;
      return this.currentProducts.includes(product);
    },
  },
};
</script>
