import HTTP from '@/api/dog';

export function updateJICCodesTypes(cc) {
  if (!cc) return;
  HTTP.get(`/countries/${cc}`).then(res => {
    if (!res.data.supported_jic_codes) {
      this.jicCodes = [];
    } else {
      this.jicCodes = res.data.supported_jic_codes.map(value => {
        const text = (value || '–').toUpperCase();
        return { value, text };
      });
    }
    if (!this.jicCodes.map(x => x.value).includes(this.item.jic_code)) {
      this.item.jic_code = null;
      if (this.jicCodes.length) this.item.jic_code = this.jicCodes[0].value;
    }
  });
}

export function updateOwnerTypes(cc) {
  const notUS = !!cc && cc !== 'US';
  HTTP.get('/applications/enum/owners').then(res => {
    this.ownerTypes = res.data;
    if (notUS) {
      this.ownerTypes = this.ownerTypes.filter(t => t.value === 'nielsen');
      this.item.owner_type = 'nielsen';
    }
  });
}

export function countryWatch(extra) {
  const opts = extra || {};

  return Object.assign(
    {
      'item.country_code': {
        handler(cc, prev) {
          if (cc !== prev) {
            this.updateJICCodesTypes(cc);
            this.updateOwnerTypes(cc);
          }
        },
      },
    },
    opts,
  );
}

export function ddrsEntity(x) {
  let label = `${x.name} (${x.client_id} / ${x.vc_id})`;
  if (x.parent) label = `${x.parent} / ${label}`;
  return { label };
}

export const _ = null;
