<template>
  <nv-app
    :navigation="navigation"
    :user="{ can: $auth.hasPermissions }"
    icon="/images/dog-icon-120x120.png"
    title="DOG"
  />
</template>

<script>
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from './router';

if (process.env.VUE_APP_GA_TRACKING) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_TRACKING,
    router,
  });
}

const navigation = [
  { heading: 'Core' },
  {
    icon: 'mdi-format-list-bulleted',
    text: 'Content Applications',
    route: '/applications/content',
  },
  {
    icon: 'mdi-format-list-bulleted',
    text: 'Campaign Applications',
    route: '/applications/campaign',
  },
  { icon: 'mdi-account-multiple-outline', text: 'VCIDs', route: '/ddrs_entities' },
  { icon: 'mdi-account-multiple', text: 'Parents', route: '/parents' },
  { icon: 'mdi-account-multiple', text: 'Owners', route: '/owners' },
  { icon: 'mdi-alarm', text: 'Scheduled Changes', route: '/scheduled_changes' },
  { icon: 'mdi-receipt', text: 'Reports', route: '/reports' },

  { heading: 'Admin' },
  {
    icon: 'mdi-application-settings-outline',
    text: 'Parameters',
    route: '/master_parameters',
  },
  {
    icon: 'mdi-clipboard-text',
    text: 'Application Templates',
    route: '/applications/templates',
  },
];

export default {
  name: 'App',
  data: () => ({
    navigation,
  }),
};
</script>
