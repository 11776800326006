import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as VComponents from 'vuetify/lib/components';
import '@mdi/font/css/materialdesignicons.css';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
  components: { ...VComponents },
});

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: colors.blueGrey.lighten3,
        secondary: colors.orange.base,
        accent: colors.purple.darken1,
        error: colors.red.darken1,
      },
    },
  },
});
