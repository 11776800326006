export function formatFilters(filters, countries) {
  const formatted = {};
  Object.keys(filters).forEach(key => {
    formatted[key] = filters[key];
    if (key === 'country_code') {
      const country = countries.find(c => c.code === filters[key]);
      if (country) {
        formatted['country'] = country.name;
      }
      delete formatted.country_code;
    }
    if (key === 'syndicated') {
      formatted['syndication'] = filters.syndicated;
      delete formatted.syndicated;
    }
  });
  return formatted;
}
export const _ = null;
