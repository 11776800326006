<template>
  <nv-tab-page>
    <template slot="toolbar">
      <v-spacer />
      <nv-table-search v-model="search" />
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="ddrsPath(item.id)">
              {{ item.name }}
            </router-link>
          </td>
          <td>
            <router-link :to="ddrsPath(item.id)">
              {{ item.client_id }} / {{ item.vc_id }}
            </router-link>
          </td>
          <td>
            {{ item.country_code | uppercase }}
          </td>
          <td :title="item.effective_date | time">
            {{ item.effective_date | time }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </nv-tab-page>
</template>

<script>
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/dog';

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'VCID', sortable: false, value: 'vc_id' },
  { text: 'Country Code', sortable: false, value: 'country_code' },
  { text: 'Effective Date', sortable: false, value: 'effective_date' },
];

export default {
  mixins: [TableMixin],
  props: { path: { type: String, required: true } },
  data: () => ({
    headers,
    tableParam: 'entities.dt',
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, this.path);
    },
    ddrsPath(ddrsId) {
      return { name: 'dog.ddrs_entities.show', params: { ddrsId } };
    },
  },
};
</script>
