<template>
  <v-row no-gutters>
    <v-col v-if="showSuspendedProducts" cols="12" class="mt-3">
      <v-subheader class="pl-0">Suspended products</v-subheader>
    </v-col>
    <v-col v-if="showDTVR" cols="12" sm="3" md="2">
      <v-checkbox
        ref="dtvr"
        v-model="suspendProducts"
        label="DTVR"
        title="Digital TV Ratings"
        value="dtvr"
        @change="onCheckboxChange"
      />
    </v-col>
    <v-col v-if="showDCRVideo" cols="12" sm="3" md="2">
      <v-checkbox
        ref="dcr_video"
        v-model="suspendProducts"
        label="DCR Video"
        title="Digital Content Ratings Video"
        value="dcr_video"
        @change="onCheckboxChange"
      />
    </v-col>
    <v-col v-if="showDCRStatic" cols="12" sm="3" md="2">
      <v-checkbox
        ref="dcr_static"
        v-model="suspendProducts"
        label="DCR Static"
        title="Digital Content Ratings Static"
        value="dcr_static"
        @change="onCheckboxChange"
      />
    </v-col>
    <v-col v-if="showMTVR" cols="12" sm="3" md="2">
      <v-checkbox
        ref="dtvr_sm"
        v-model="suspendProducts"
        label="DTVR Subminute"
        title="MTVR subminute enabled flag"
        value="dtvr_sm"
        @change="onCheckboxChange"
      />
    </v-col>
    <v-col v-if="showDAR" cols="12" sm="3" md="2">
      <v-checkbox
        ref="dar"
        v-model="suspendProducts"
        label="DAR"
        title="Digital Ad Ratings"
        value="dar"
        data-cy="dar-product-checkbox"
        @change="onCheckboxChange"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: null },
    products: { type: Array, default: null },
  },
  data: () => ({
    suspendProducts: [],
  }),
  computed: {
    showDTVR() {
      return this.products != null && this.products.includes('dtvr');
    },
    showDCRVideo() {
      return this.products != null && this.products.includes('dcr_video');
    },
    showDCRStatic() {
      return this.products != null && this.products.includes('dcr_static');
    },
    showDAR() {
      return this.products != null && this.products.includes('dar');
    },
    showMTVR() {
      return this.products != null && this.products.includes('dtvr_sm');
    },
    showSuspendedProducts() {
      return (
        this.showDTVR || this.showDCRVideo || this.showDCRStatic || this.showDAR || this.showMTVR
      );
    },
    suspendedProductsList() {
      return this.suspendProducts.map(prod => ({ product: prod }));
    },
  },
  watch: {
    value() {
      this.suspendProducts = [];
      if (this.value) {
        this.suspendProducts = this.value.map(prod => prod.product);
      }
    },
  },
  created() {
    this.suspendProducts = [];
    if (this.value) {
      this.suspendProducts = this.value.map(prod => prod.product);
    }
  },
  methods: {
    onCheckboxChange() {
      this.$emit('input', this.suspendedProductsList);
    },
  },
};
</script>
