<template>
  <nv-page :header="item.name">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn v-if="canEdit" type="edit" data-cy="edit-button" @click="editDialog.show = true" />
      <v-btn v-if="$auth.hasPermissions('dog')" color="accent" @click="generateKey(item)">
        <v-icon class="mr-1">mdi-update</v-icon>
        <span>CBC Key</span>
      </v-btn>
      <nv-confirm ref="confirm" />
      <v-menu v-if="toolbarMenu.length" offset-y>
        <template #activator="{ on }">
          <v-btn data-cy="toolbar-menu" icon text v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="toolbarMenu.includes('copy')"
            data-cy="copy-button"
            @click="copyDialog.show = true"
          >
            <v-list-item-avatar>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Copy
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-alert ref="version-alert" :value="item.row_version === 0" type="error">
      Application needs to be upgraded to the new validation format. Please EDIT and SAVE the
      application to proceed.
    </v-alert>
    <v-alert ref="active-alert" :value="isTransmitting" type="error">
      Application was set to inactive but still has product tags enabled. Please return application
      back to active then suspend all enabled product tags to completely stop transmission of data
      through the SDK. Application can be set back to inactive after.
    </v-alert>
    <v-card class="pa-4">
      <v-row dense>
        <nv-show cols="12" sm="8" header="ID">{{ item.id }}</nv-show>
        <nv-show cols="12" sm="4" header="Status">
          {{ item.status | enumLabel(statuses) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="Name">{{ item.name }}</nv-show>
        <nv-show cols="12" sm="4" header="Template">
          <span v-if="item.application_template_id">
            {{ item.template && item.template.name }}
          </span>
        </nv-show>
        <nv-show cols="12" sm="4" header="Owner">
          {{ item.owner && item.owner.name }}
          <span v-if="item.owner">[APRP{{ item.owner.id }}]</span>
        </nv-show>

        <nv-show cols="12" sm="4" header="Description">
          {{ item.description }}
        </nv-show>
        <nv-show cols="12" sm="4" header="Country">
          <span v-if="item.country">{{ item.country.name }}</span>
        </nv-show>
        <v-col cols="12" sm="4">
          <client-show v-model="item" />
        </v-col>

        <nv-show cols="12" sm="4" header="Owner Type">
          {{ item.owner_type | enumLabel(ownerTypes) }}
        </nv-show>
        <nv-show cols="12" sm="4" header="JIC Code">
          {{ item.jic_code }}
        </nv-show>
        <nv-show cols="12" sm="4" header="Integration Type">
          {{ item.app_type | enumLabel(appTypes) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="OS">
          {{ item.operating_system | enumLabel(operatingSystems) }}
        </nv-show>
        <nv-show cols="12" sm="4" header="Implementation">
          {{ item.implementation_type | enumLabel(implementationTypes) }}
        </nv-show>
        <nv-show cols="12" sm="4" header="Deployment">
          {{ item.deployment_type | enumLabel(deploymentTypes) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="Website URL">
          <a :href="item.website_url" target="_blank">
            <span :title="item.website_url" class="text-truncate d-block">
              {{ item.website_url }}
            </span>
          </a>
        </nv-show>
        <nv-show cols="12" sm="4" header="Support URL">
          <a :href="item.support_url" target="_blank">
            <span :title="item.support_url" class="text-truncate d-block">
              {{ item.support_url }}
            </span>
          </a>
        </nv-show>
        <nv-show cols="12" sm="4" header="Store URL">
          <a :href="item.store_url" target="_blank">
            <span :title="item.store_url" class="text-truncate d-block">{{ item.store_url }}</span>
          </a>
        </nv-show>
        <nv-show cols="12" sm="4" header="Products" no-placeholder>
          <product-chip-group
            v-model="item.products"
            :suspend-products="item.suspended_products"
            data-cy="product-chip-group"
          />
        </nv-show>
        <nv-show cols="12" sm="4" header="CBC Key">
          {{ item.cbc_key | - }}
        </nv-show>
      </v-row>
    </v-card>

    <v-card class="mt-4 elevation-2">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="parameters-tab">Parameters</v-tab>
        <v-tab data-cy="sdk-configurations-tab">
          SDK Configurations
        </v-tab>
        <v-tab data-cy="configuration-tab">Configuration</v-tab>
        <v-tab data-cy="scheduled-changes-tab">Scheduled Changes</v-tab>
        <v-tab data-cy="history-tab">History</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item :disabled="!item.products" data-cy="parameters-pane">
          <parameters
            v-if="item.id"
            ref="parameters"
            :application-id="item.id"
            :is-editable="canEdit"
          />
        </v-tab-item>
        <v-tab-item :disabled="!item.products" data-cy="sdk-configurations-pane">
          <s-d-k-configurations v-if="item.id" :application-id="item.id" :is-editable="canEdit" />
        </v-tab-item>
        <v-tab-item data-cy="configuration-pane">
          <configuration-flags
            v-if="item.id"
            :value="item"
            :is-editable="canEdit"
            @input="updateConfiguration"
          />
        </v-tab-item>
        <v-tab-item data-cy="scheduled-changes-pane">
          <scheduled-changes-tab v-if="item.id" v-model="item.id" :can-edit="canEdit" />
        </v-tab-item>
        <v-tab-item>
          <history :application-id="$route.params.applicationId" data-cy="history-pane" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <edit-form v-model="editDialog.show" :editing="item" @saved="saved" />

    <copy-form
      v-if="item.id"
      v-model="copyDialog.show"
      :application-id="item.id"
      :app-type="item.app_type"
      :country-code="item.country_code"
    />
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import Parameters from '@/components/applications/Parameters.vue';
import SDKConfigurations from '@/components/applications/SDKConfigurations.vue';
import EditForm from '@/components/applications/EditForm.vue';
import CopyForm from '@/components/applications/CopyForm.vue';
import History from '@/components/applications/History.vue';
import ConfigurationFlags from '@/components/applications/ConfigurationFlags.vue';
import ScheduledChangesTab from '@/components/applications/ScheduledChangesTab.vue';
import ProductChipGroup from '@/components/ProductChipGroup.vue';
import ClientShow from '@/components/applications/ClientShow.vue';

export default {
  components: {
    Parameters,
    SDKConfigurations,
    EditForm,
    CopyForm,
    History,
    ConfigurationFlags,
    ProductChipGroup,
    ScheduledChangesTab,
    ClientShow,
  },
  data: () => ({
    item: {
      products: [],
      suspended_products: [],
      id: null,
    },
    editDialog: { show: false, id: null },
    copyDialog: { show: false, id: null },
    templateDialog: { show: false, id: null },
    tabs: 0,
    toolbarMenu: [],
    statuses: [],
    operatingSystems: [],
    implementationTypes: [],
    ownerTypes: [],
    appTypes: [],
    deploymentTypes: [],
  }),
  computed: {
    canEdit() {
      if (!this.$auth.hasPermissions('dog.applications')) {
        return false;
      }
      return this.item.status !== 'archived';
    },
    isTransmitting() {
      return this.item.products.length !== 0 && this.item.status == 'inactive';
    },
  },
  created() {
    this.fetchData();
    this.enumFetch(HTTP, {
      statuses: '/applications/enum/statuses',
      operatingSystems: '/applications/enum/operating_systems',
      implementationTypes: '/applications/enum/implementations',
      ownerTypes: '/applications/enum/owners',
      appTypes: '/applications/enum/types',
      deploymentTypes: '/applications/enum/deployment_types',
    });
  },
  methods: {
    fetchData() {
      HTTP.get(`/applications/${this.$route.params.applicationId}`).then(res => {
        this.item = res.data;
        this.toolbarMenu = [];
        if (
          this.$auth.hasPermissions('dog.applications') &&
          this.item.status === 'active' &&
          this.item.row_version > 0
        ) {
          this.toolbarMenu.push('copy');
        }
      });
    },
    generateKey(item) {
      this.$refs.confirm
        .open('Are you sure you want to generate a new CBC Key for this integration?', '')
        .then(() => {
          HTTP.put(`/applications/${item.id}/gen_key`).then(res => {
            this.item = res.data;
            this.$flash('success', 'CBC Key generated successfully!');
          });
        });
    },
    saved() {
      this.fetchData();
      this.$refs.parameters.tableFetch();
    },
    updateConfiguration(item) {
      if (!item.user_opt_out) {
        item.user_opt_out = null;
      }
      HTTP.put(`/applications/${this.item.id}`, item).then(({ data }) => {
        this.item = data;
        this.$flash('success', 'Configuration updated!');
      });
    },
    enumFetch,
  },
};
</script>
