<template>
  <v-row dense>
    <v-col cols="12">
      <v-autocomplete
        ref="countryCode"
        v-model="countryCode"
        :rules="rules.countryCode"
        :items="countries"
        item-text="name"
        item-value="code"
        label="Country"
        required
      />
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        ref="templateId"
        v-model="template_id"
        :loading="isLoading"
        :items="templates"
        :rules="rules.templateID"
        :search-input.sync="templateSearch"
        data-cy="template-autocomplete"
        placeholder="Start typing to search"
        label="Template"
        item-text="name"
        item-value="id"
        required
        @input="$emit('input', template_id)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export function searchTemplates() {
  // if country code is missing then don't search for templates
  if (!this.countryCode) {
    this.templates = [];
    return;
  }

  if (!this.templates) {
    return;
  }

  // Input string is too short
  if (this.templateSearch && this.templateSearch.length < 3) return;

  // Items have already been requested
  if (this.isLoading) return;

  this.isLoading = true;

  HTTP.get('/applications/templates', {
    params: {
      per_page: 300,
      search: this.templateSearch,
      filter: { country_code: this.countryCode, status: 'active' },
    },
  })
    .then(res => {
      this.templates = res.data;
    })
    .finally(() => {
      this.isLoading = false;
    });
}

export default {
  props: { value: { type: Number, default: null } },
  data: () => ({
    countryCode: null,
    template_id: null,
    rules,
    templates: [],
    countries: [],
    isLoading: false,
    templateSearch: '',
    enumFetch,
  }),
  watch: {
    templateSearch: debounce(searchTemplates, 500),
    countryCode: searchTemplates,
  },
  created() {
    this.enumFetch(HTTP, {
      countries: '/countries?per_page=300&filter[status]=active',
    });
  },
};
</script>
