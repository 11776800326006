<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      title="New Parameter"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            ref="name"
            v-model="item.master_parameter_id"
            :rules="rules.masterParameterID"
            :items="masterParameters"
            data-cy="param-name"
            item-text="name"
            item-value="id"
            label="Name"
            hint="The parameter name"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export default {
  mixins: [FormMixin],
  props: {
    applicationId: { type: String, default: null },
    templateId: { type: [Number, String], default: null },
  },
  data: () => ({
    rules,
    allMasterParameters: [],
    masterParameters: [],
  }),
  computed: {
    apiUrl() {
      return this.applicationId
        ? `/applications/${this.applicationId}/parameters`
        : `/applications/templates/${this.templateId}/parameters`;
    },
  },
  created() {
    this.enumFetch(HTTP, {
      allMasterParameters: '/master_parameters?per_page=300',
    });
  },
  methods: {
    formReady() {
      HTTP.get(this.apiUrl).then(({ data }) => {
        const ids = data.map(p => p.master_parameter.id);
        this.masterParameters = this.allMasterParameters.filter(mp => !ids.includes(mp.id));
      });
    },
    formSave() {
      return this.submitForm(HTTP, this.apiUrl, {
        success: res => {
          this.$flash('success', `Parameter ${res.data?.master_parameter?.name} added!`);
        },
      });
    },
    enumFetch,
  },
};
</script>
