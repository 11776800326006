import Vue from 'vue';
import Router from 'vue-router';
import { withAuth } from '@nielsen-media/ui-toolkit-vue-api';
import store from 'store';

const ns = store.namespace('applications');

import ApplicationsList from '@/views/applications/List.vue';
import ApplicationsContentList from '@/views/applications/ContentList.vue';
import ApplicationsCampaignList from '@/views/applications/CampaignList.vue';
import ApplicationsShow from '@/views/applications/Show.vue';

import ApplicationTemplatesList from '@/views/application_templates/List.vue';
import ApplicationTemplatesShow from '@/views/application_templates/Show.vue';

import DDRSEntitiesList from '@/views/ddrs_entities/List.vue';
import DDRSEntitiesShow from '@/views/ddrs_entities/Show.vue';

import ParentsList from '@/views/parents/List.vue';
import ParentsShow from '@/views/parents/Show.vue';

import OwnersList from '@/views/owners/List.vue';
import OwnersShow from '@/views/owners/Show.vue';

import ScheduledChangesList from '@/views/scheduled_changes/List.vue';

import MasterParametersList from '@/views/master_parameters/List.vue';

import ReportsList from '@/views/reports/List.vue';

Vue.use(Router);

export default withAuth(
  new Router({
    mode: 'history',
    routes: [
      { path: '/', redirect: '/applications' },
      { path: '/dog/applications', redirect: '/applications' },
      {
        path: '/applications',
        component: ApplicationsList,
        name: 'dog.applications',
        beforeEnter: (to, from, next) => {
          const productType = ns.get('productType');
          if (productType) {
            next(`/applications/${productType}`);
          } else {
            next();
          }
        },
      },
      {
        path: '/applications/content',
        component: ApplicationsContentList,
        name: 'dog.applications.content',
      },
      {
        path: '/applications/campaign',
        component: ApplicationsCampaignList,
        name: 'dog.applications.campaign',
      },
      {
        path: '/applications/templates',
        component: ApplicationTemplatesList,
        name: 'dog.applications.templates',
      },
      {
        path: '/applications/templates/:templateId',
        component: ApplicationTemplatesShow,
        name: 'dog.applications.templates.show',
      },
      {
        path: '/applications/:applicationId',
        component: ApplicationsShow,
        name: 'dog.applications.show',
      },
      {
        path: '/ddrs_entities',
        component: DDRSEntitiesList,
        name: 'dog.ddrs_entities.list',
      },
      {
        path: '/ddrs_entities/:ddrsId',
        component: DDRSEntitiesShow,
        name: 'dog.ddrs_entities.show',
      },
      {
        path: '/parents',
        component: ParentsList,
        name: 'dog.parents.list',
      },
      {
        path: '/parents/:parentId',
        component: ParentsShow,
        name: 'dog.parents.show',
      },
      { path: '/owners', component: OwnersList, name: 'dog.owners' },
      { path: '/owners/:ownerId', component: OwnersShow, name: 'dog.owners.show' },
      {
        path: '/owners/:ownerId/applications/:applicationId',
        component: ApplicationsShow,
        name: 'dog.owner.applications.show',
      },
      {
        path: '/scheduled_changes',
        component: ScheduledChangesList,
        name: 'dog.scheduled_changes',
      },
      {
        path: '/master_parameters',
        component: MasterParametersList,
        name: 'dog.master_parameters',
      },
      { path: '/dog', redirect: '/applications' },
      { path: '/applications/content/:applicationId', redirect: '/applications/:applicationId' },
      { path: '/applications/campaign/:applicationId', redirect: '/applications/:applicationId' },
      {
        path: '/reports',
        component: ReportsList,
        name: 'dog.reports',
      },
    ],
  }),
);
