<template>
  <v-col class="nv-placeholder pa-0">
    <v-chip v-for="product in value" :key="product" :class="chipStyle(product)" class="ma-1" small>
      {{ product | enumProducts(products) }}
    </v-chip>
  </v-col>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  filters: {
    enumProducts(input, enums) {
      if (!enums) return null;

      const item = enums.find(e => e.code === input) || {};
      return item.name;
    },
  },
  props: {
    value: { type: [Array], default: null },
    suspendProducts: { type: Array, default: null },
  },
  data: () => ({
    products: [],
  }),
  created() {
    this.enumFetch(HTTP, {
      products: '/applications/enum/products',
    });
  },
  methods: {
    chipStyle(product) {
      if (this.suspendProducts == null) return 'prod';
      const susProducts = this.suspendProducts.map(prod => prod.product);
      if (susProducts.includes(product)) return 'prod-suspended';
      return 'prod';
    },
    enumFetch,
  },
};
</script>

<style>
.prod-suspended {
  opacity: 0.4;
}

.prod-suspended .v-chip__content::after {
  content: ' - suspended';
  padding-left: 3px;
}
</style>
