<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      :errors="formErrors"
      :loading="loading"
      title="Bulk Create Applications"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <template-selector
        v-if="value"
        ref="template-selector"
        v-model="item.application_template_id"
      />

      <v-row dense justify="space-between">
        <v-col cols="auto">
          <!-- :rules="rules.bulkFile" doesn't affect anything here -->
          <upload-button ref="ubtn" v-model="item.bulk_file" />
        </v-col>
        <v-col cols="auto">
          <v-btn v-if="!item.bulk_file" :href="example" color="primary" target="_blank">
            Example
            <v-icon right>mdi-cloud-download</v-icon>
          </v-btn>
        </v-col>

        <v-dialog v-model="appsDialog.show" persistent max-width="1200px">
          <uploaded-apps v-if="appsDialog.apps" :apps="appsDialog.apps" @close="closeApps" />
        </v-dialog>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';
import TemplateSelector from '@/components/applications/TemplateSelector.vue';
import UploadButton from '@/components/UploadButton.vue';
import UploadedApps from '@/components/applications/UploadedApps.vue';

export default {
  components: { UploadButton, UploadedApps, TemplateSelector },
  mixins: [FormMixin],
  props: { ownerId: { type: Number, default: null, required: true } },
  data: () => ({
    item: {},
    rules,
    example: 'https://nlsn-eng-assets.s3.amazonaws.com/ui/v1/examples/bulk-create-applications.tsv',
    appsDialog: { show: false, apps: null },
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get('/applications/templates', { params: { per_page: 300 } }).then(res => {
        this.templates = res.data;
      });
    },
    openApps(apps) {
      this.appsDialog.show = true;
      this.appsDialog.apps = apps;
    },
    closeApps() {
      this.appsDialog.apps = null;
      this.appsDialog.show = false;
      this.formCancel();
    },
    formSave() {
      const formData = new FormData();
      formData.append('application_template_id', this.item.application_template_id);
      if (this.item.bulk_file) {
        formData.append('bulk_file', this.item.bulk_file, 'bulk-create.tsv');
      }

      return HTTP.post(`/owners/${this.ownerId}/applications/bulk`, formData, {
        // Set request timeout to 2 minutes
        timeout: 120000,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(res => {
          this.$flash('success', `${res.data.rows_affected} Applications created!`);
          this.openApps(res.data.uploaded);
          return res;
        })
        .catch(err => {
          this.formErrorHandler(err);
          return Promise.reject(err);
        });
    },
    formReady() {
      this.$refs.ubtn?.resetFile();
    },
    formSubmit(form) {
      if (form?.validate()) {
        this.loading = true;
        this.formSave()
          .then(() => {
            this.$emit('saved');
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
