import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import './plugins/common';
import App from './App.vue';
import router from './router';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { AuthPlugin } from '@nielsen-media/ui-toolkit-vue-api';
import { EventBus } from '@nielsen-media/ui-toolkit-vue';

// https://docs.sentry.io/platforms/javascript/vue/
// https://<key>@sentry.io/<project>
const SENTRY_DSN_URL = process.env.VUE_APP_SENTRY_DSN;

if (SENTRY_DSN_URL) {
  Sentry.init({
    dsn: SENTRY_DSN_URL,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

Vue.config.productionTip = false;

Vue.use(AuthPlugin, { EventBus, router });

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
