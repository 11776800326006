<template>
  <nv-page :header="title">
    <template slot="toolbar">
      <application-add-menu :available="['template', 'custom']" @selected="appTypeSelected" />
      <v-spacer />
      <nv-table-search v-model="search" class="hidden-xs-only" />
    </template>
    <template slot="filters">
      <nv-table-filters
        ref="applied-filters"
        :value="formattedFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet
          ref="filter-sheet"
          :product-type="settings.product_type"
          @changed="setTableFilters"
        />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="applicationPath(item.id)">
              {{ item.name }}
              <br />
              <span class="text-caption">{{ item.description }}</span>
            </router-link>
          </td>
          <td>{{ item.id }}</td>
          <td>
            <client-show :value="item" display-type="table" />
          </td>
          <td>{{ item.operating_system | enumLabel(operatingSystems) }}</td>
          <td>
            {{ item.implementation_type | enumLabel(implementationTypes) }}
          </td>
          <td>{{ item.owner.name }}</td>
          <td>{{ item.owner_type | enumLabel(ownerTypes) }}</td>
          <td>{{ item.app_type | enumLabel(appTypes) }}</td>
          <td>{{ item.syndicated | enumLabel(syndicated) }}</td>
          <td>{{ item.deployment_type | enumLabel(deploymentTypes) }}</td>
          <td>{{ item.cbc_key | '-' }}</td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="settingsDialog" persistent max-width="300px">
      <v-card data-cy="product-type-dialog">
        <v-card-title primary-title>
          <span class="text-h6">Product Type</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-btn-toggle>
                <v-btn
                  v-for="item in productTypes"
                  :key="item.value"
                  :value="item.value"
                  text
                  small
                  @click="selectedProductType(item.value)"
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <new-template-form v-model="dialog.template" @saved="tableFetch" />

    <new-custom-form v-model="dialog.custom" :product-type="productType" @saved="tableFetch" />
  </nv-page>
</template>

<script>
import { debounce } from 'lodash';
import HTTP from '@/api/dog';
import { formatFilters } from '@/helpers/filters';
import FilterSheet from '@/components/applications/FilterSheet.vue';
import NewCustomForm from '@/components/applications/NewCustomForm.vue';
import NewTemplateForm from '@/components/applications/NewTemplateForm.vue';
import ApplicationAddMenu from '@/components/ApplicationAddMenu.vue';
import ClientShow from '@/components/applications/ClientShow.vue';
import store from 'store';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

const ns = store.namespace('applications');
const fns = store.namespace('filters');

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'ID', sortable: false, value: 'id' },
  { text: 'Client ID/Sub-brand', sortable: false, value: 'client_id' },
  { text: 'OS', sortable: false, value: 'operating_system' },
  { text: 'Implementation', sortable: false, value: 'implementation_type' },
  { text: 'Owner', sortable: false, value: 'owner' },
  { text: 'Ownership', sortable: false, value: 'owner_type' },
  { text: 'Integration Type', sortable: false, value: 'app_type' },
  { text: 'Syndication', sortable: false, value: 'syndicated' },
  { text: 'Deployment', align: 'left', sortable: false, value: 'deployment_type' },
  { text: 'CBC Key', sortable: false, value: 'cbc_key' },
];

function fetchOwners() {
  HTTP.get('/owners', { params: { search: this.ownerSearch, per_page: 20 } }).then(res => {
    this.owners = res.data;
  });
}

export default {
  components: {
    NewCustomForm,
    NewTemplateForm,
    FilterSheet,
    ApplicationAddMenu,
    ClientShow,
  },
  mixins: [TableMixin],
  props: {
    productType: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    headers,
    formatFilters,
    dialog: { template: false, custom: false },
    owners: [],
    ownerSearch: '',
    statuses: [],
    operatingSystems: [],
    implementationTypes: [],
    ownerTypes: [],
    appTypes: [],
    deploymentTypes: [],
    syndicated: [],
    productTypes: [],
    countries: [],
  }),
  computed: {
    settingsDialog() {
      return !this.settings.product_type;
    },
    formattedFilters() {
      return formatFilters(this.tableFilters, this.countries);
    },
  },
  watch: {
    ownerSearch: debounce(fetchOwners, 500),
  },
  created() {
    if (this.productType) {
      this.settings.product_type = this.productType;
      ns.set('productType', this.productType);
    }

    this.enumFetch(HTTP, {
      operatingSystems: '/applications/enum/operating_systems',
      implementationTypes: '/applications/enum/implementations',
      ownerTypes: '/applications/enum/owners',
      appTypes: '/applications/enum/types',
      deploymentTypes: '/applications/enum/deployment_types',
      syndicated: '/applications/enum/syndicated',
      productTypes: '/applications/enum/product_types',
      countries: '/countries?per_page=300&filter[status]=active',
    });
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/applications');
    },
    appTypeSelected(type) {
      this.dialog[type] = true;
    },
    applicationPath(applicationId) {
      return { name: 'dog.applications.show', params: { applicationId } };
    },
    selectedProductType(productType) {
      this.$router.push(`/applications/${productType}`);
    },
    enumFetch,
    setTableFilters(filters) {
      fns.set('applications', filters);
      this.tableFilters = { ...filters };
    },
  },
};
</script>

<style scoped>
table td {
  font-size: 11px !important;
}
.caption {
  font-size: 10px !important;
}
</style>
