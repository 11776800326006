<template>
  <nv-page header="Scheduled Changes">
    <template slot="toolbar">
      <state-filter ref="state-filter" @changed="setTableFilters" />
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.scheduled_on }}</td>
          <td>
            <router-link :to="applicationPath(item.application_id)">
              {{ item.application_id }}
            </router-link>
          </td>
          <td>
            <proposed-changes-show :item="item" :show-current-state="false" :show-header="false" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import ProposedChangesShow from '@/components/applications/ProposedChangesShow.vue';
import StateFilter from '@/components/applications/scheduled_changes/StateFilter.vue';

const headers = [
  { text: 'Scheduled On', sortable: true, value: 'scheduled_on' },
  { text: 'Application ID', sortable: false, value: 'application_id' },
  { text: 'Changes', sortable: false, value: 'changes' },
];

export default {
  components: { ProposedChangesShow, StateFilter },
  mixins: [TableMixin],
  data: () => ({
    headers,
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/scheduled_changes');
    },
    applicationPath(applicationId) {
      return { name: 'dog.applications.show', params: { applicationId } };
    },
  },
};
</script>
