<template>
  <nv-page header="Master Parameters">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-up" />
      <nv-btn v-if="$auth.hasPermissions('dog.master_parameters')" type="add" @click="openEdit()" />
      <v-spacer class="hidden-sm-and-down" />
      <nv-table-search v-model="search" class="ml-4 mr-0 hidden-xs-only" />
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.default_value }}</td>
          <td>{{ item.encoding | humanize() }}</td>
          <td>{{ item.parameter_type | enumLabel(parameterTypes) }}</td>
          <td>
            <product-chip-group v-model="item.products" :suspend-products="item.suspendProducts" />
          </td>
          <td>{{ item.read_only | humanize('boolean') }}</td>
          <td>{{ item.status | enumLabel(statuses) }}</td>
          <td class="text-right" style="white-space:nowrap">
            <v-btn
              v-if="$auth.hasPermissions('dog.master_parameters')"
              data-unit="master-parameter-edit"
              icon
              @click="openEdit(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn data-unit="master-parameter-history" icon @click="openHistory(item.id)">
              <v-icon>mdi-clock-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <edit-form v-model="editDialog.show" :editing="editDialog.edit" @saved="tableFetch" />

    <v-dialog v-model="historyDialog.show" persistent max-width="1200px" scrollable>
      <history v-if="historyDialog.id" :parameter-id="historyDialog.id" @close="closeHistory" />
    </v-dialog>
  </nv-page>
</template>

<script>
import { debounce } from 'lodash';
import HTTP from '@/api/dog';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import EditForm from '@/components/master_parameters/EditForm.vue';
import History from '@/components/master_parameters/History.vue';
import ProductChipGroup from '@/components/ProductChipGroup.vue';

const headers = [
  { text: 'Key Name', sortable: true, value: 'name' },
  { text: 'Description', sortable: false, value: 'value' },
  { text: 'Default Value', sortable: false, value: 'value' },
  { text: 'Default Encoding', sortable: false, value: 'encoding' },
  { text: 'Type', sortable: false, value: 'parameter_type' },
  { text: 'Products', sortable: false, value: 'products' },
  { text: 'Read Only', sortable: false, value: 'read_only' },
  { text: 'Status', sortable: false, value: 'read_only' },
  { text: 'Action', sortable: false, value: 'action' },
];

function fetchOwners() {
  HTTP.get('/owners', { params: { search: this.ownerSearch, per_page: 20 } }).then(res => {
    this.owners = res.data;
  });
}

export default {
  components: {
    EditForm,
    History,
    ProductChipGroup,
  },
  filters: {
    enumProducts(input, enums) {
      if (!enums) return null;

      const item = enums.find(e => e.code === input) || {};
      return item.name;
    },
  },
  mixins: [TableMixin],
  data: () => ({
    headers,
    filters: {
      status: 'active',
    },
    statuses: [],
    parameterTypes: [],
    products: [],
    editDialog: { show: false, edit: null },
    historyDialog: { show: false, id: null },
  }),
  watch: { ownerSearch: debounce(fetchOwners, 500) },
  created() {
    this.enumFetch(HTTP, {
      statuses: '/applications/enum/statuses',
      parameterTypes: '/parameters/enum/types',
      products: '/applications/enum/products',
    });
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/master_parameters');
    },
    openEdit(item = null) {
      this.editDialog.edit = item;
      this.editDialog.show = true;
    },
    openHistory(id) {
      this.historyDialog.id = id;
      this.historyDialog.show = true;
    },
    closeHistory() {
      this.historyDialog.id = null;
      this.historyDialog.show = false;
    },
    enumFetch,
  },
};
</script>
