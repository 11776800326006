/* eslint-disable no-useless-escape */
import vrb from 'bsm-validation-rules';
import { types } from '@/helpers/validations';

export const rules = {
  name: [vrb.presence(), ...types.parameterName],
  value: [
    vrb.presence(),
    ...types.string(400),
    vrb.format(
      /^[\[\{]?[A-Za-z0-9&_\[\]\/\?\%\-:\.\|\+~!,=]+[\]\}]?$/,
      'must be valid value format',
    ),
  ],
  description: [...types.description],
  status: [vrb.presence(), vrb.typeOf('string')],
};
