<template>
  <nv-show :header="showHeader ? 'Changes' : ''">
    <ul class="pt-4 pb-2 pl-0">
      <li v-for="change in item.proposed_changes" :key="change.attribute_name" class="mb-1">
        {{ change.attribute_name | humanize }}: &nbsp;
        <changelog-item
          :show-current-state="showCurrentState"
          :type="change.attribute_name"
          :before="change.before | humanize"
          :after="change.after | humanize"
        />
      </li>
    </ul>
  </nv-show>
</template>

<script>
import ChangelogItem from '@/components/ChangelogItem.vue';

export default {
  components: { ChangelogItem },
  props: {
    item: { type: Object, required: true },
    showCurrentState: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
td li {
  font-size: small;
}
.v-card li {
  margin-bottom: 8px;
}
</style>
