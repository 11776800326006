<template>
  <nv-tab-page>
    <template slot="toolbar">
      <nv-btn v-if="canEdit()" ref="add-btn" type="add" data-cy="add-sdk" @click="openForm()" />
      <v-spacer />
      <nv-table-search v-model="search" />
    </template>
    <template slot="filters">
      <nv-table-filters :value="tableFilters" @clear="$refs['filter-sheet'].clearFilters()">
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item.status="{ item }">
        {{ item.status | enumLabel(enumSDKStatuses) }}
      </template>
      <template #item.edit="{ item }">
        <v-btn v-if="canEdit()" ref="edit-btn" icon @click="openForm(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <edit-form
      v-model="form.show"
      :editing="form.edit"
      :application-id="applicationId"
      :app-count="form.appCount"
      :template-id="templateId"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import EditForm from '@/components/sdk_configurations/EditForm.vue';
import FilterSheet from '@/components/sdk_configurations/FilterSheet.vue';

const headers = [
  { text: 'Name', sortable: false, value: 'name' },
  { text: 'Value', sortable: false, value: 'value' },
  { text: 'Description', sortable: false, value: 'description' },
  { text: 'Status', sortable: false, value: 'status' },
  { align: 'right', sortable: false, value: 'edit' },
];

export default {
  components: { EditForm, FilterSheet },
  mixins: [TableMixin],
  props: {
    applicationId: { type: String, default: null },
    templateId: { type: [Number, String], default: null },
    appCount: { type: Number, default: 0 },
    isEditable: { type: Boolean, default: true },
  },
  data: () => ({
    headers,
    form: { show: false, edit: null, appCount: 0 },
    enumSDKStatuses: [],
    tableParam: 'sdk.dt',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumSDKStatuses: '/sdks/configuration/enum/statuses',
    });
  },
  methods: {
    tableFetch() {
      if (this.applicationId) {
        this.paginateTableData(HTTP, `/applications/${this.applicationId}/sdk_configurations`);
      } else {
        this.paginateTableData(
          HTTP,
          `/applications/templates/${this.templateId}/sdk_configurations`,
        );
      }
    },
    openForm(item = null) {
      this.form.edit = item;
      this.form.appCount = item ? this.appCount : 0;
      this.form.show = true;
    },
    canEdit() {
      if (!this.isEditable) {
        return false;
      }
      // Template SDK Configuration
      if (this.templateId) {
        if (this.appCount === 0) {
          return this.$auth.hasPermissions('dog.application_templates');
        }
        return false;
      }
      // Application SDK Configuration
      if (this.applicationId) {
        return this.$auth.hasPermissions('dog.parameters');
      }
      return false;
    },
    enumFetch,
  },
};
</script>
