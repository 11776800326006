<template>
  <nv-tab-page>
    <template slot="toolbar">
      <nv-btn
        v-if="canEdit"
        ref="add-button"
        type="add"
        data-cy="add-scheduled-change-btn"
        @click="form.show = true"
      />
      <state-filter @changed="setTableFilters" />
    </template>

    <v-container fluid>
      <v-data-iterator
        :items="items"
        :loading="loading"
        :options.sync="table"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :items-per-page.sync="table.itemsPerPage"
        no-data-text="No Scheduled Changes"
      >
        <v-row dense>
          <v-col v-for="item in items" :key="item.id" md="4" cols="12">
            <v-card height="100%">
              <v-card-text>
                <nv-show header="Scheduled Date">{{ item.scheduled_on }}</nv-show>
                <proposed-changes-show :item="item" :show-current-state="false" />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  v-if="!item.applied"
                  ref="delete"
                  color="error"
                  @click="deleteScheduledChange(item.id)"
                >
                  <v-icon class="mr-1">mdi-delete-forever</v-icon>
                  Delete
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-data-iterator>
    </v-container>

    <scheduled-edit-form v-model="form.show" :application-id="value" @saved="tableFetch" />

    <nv-confirm ref="confirm" />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import ScheduledEditForm from '@/components/applications/ScheduledEditForm.vue';
import StateFilter from '@/components/applications/scheduled_changes/StateFilter.vue';
import ProposedChangesShow from '@/components/applications/ProposedChangesShow.vue';

export default {
  components: { ScheduledEditForm, ProposedChangesShow, StateFilter },
  mixins: [TableMixin],
  props: {
    value: { type: String, default: null, required: true },
    canEdit: { type: Boolean, default: true },
  },
  data: () => ({
    items: [],
    form: { show: false },
    tableParam: 'changes.dt',
    table: {
      itemsPerPage: 20,
    },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/applications/${this.value}/scheduled_changes`);
    },
    deleteScheduledChange(id) {
      this.$refs.confirm.open('Delete Scheduled Change').then(() => {
        HTTP.delete(`/scheduled_changes/${id}`).then(() => {
          this.$flash('warning', 'Scheduled change deleted.');
          this.tableFetch();
        });
      });
    },
    enumFetch,
  },
};
</script>
