<script>
import HTTP from '@/api/dog';
import ApplicationsFilterSheet from './ApplicationsFilterSheet';
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import store from 'store';

const fns = store.namespace('filters');
const defaultFilters = {
  status: 'active',
  deployment_type: 'production',
  product: null,
  jic_code: null,
  country_code: null,
  operating_system: null,
  owner_type: null,
  syndicated: null,
};

export default {
  extends: ApplicationsFilterSheet,
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      ...defaultFilters,
      ...fns.get('applications'),
    },
  }),
  created() {
    this.enumFetch(HTTP, {
      deploymentTypes: '/applications/enum/deployment_types',
    });
  },
  methods: {
    clearFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = defaultFilters[key];
      });
    },
  },
};
</script>
