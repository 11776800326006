<template>
  <v-autocomplete
    v-model="valueInput"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :label="label"
    :data-cy="selectorType"
    :filter="customFilter"
    item-text="name"
    item-value="id"
    multi-line
    clearable
    return-object
    @change="initialized = false"
  >
    <template #selection="{ item }">
      <v-avatar
        v-if="item.country_code && item.country_code.length > 0"
        color="info"
        size="30"
        class="ma-1"
        tile
      >
        <span v-text="item.country_code.toUpperCase()"></span>
      </v-avatar>
      <span>{{ `${item.parent} / ${item.name} (${item.client_id} ${item.vc_id})` }}</span>
    </template>

    <template #item="{ item }">
      <v-avatar
        v-if="item.country_code && item.country_code.length > 0"
        color="info"
        size="30"
        class="ma-1"
        tile
      >
        <span v-text="item.country_code.toUpperCase()"></span>
      </v-avatar>
      <span>{{ `${item.parent} / ${item.name} (${item.client_id} ${item.vc_id})` }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => {} },
  },
  data: () => ({
    search: '',
    isLoading: false,
    selectorType: '',
    initialized: false,
    items: [],
  }),
  computed: {
    valueInput: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          this.$emit('input', value);
        } else {
          this.$emit('input', {});
        }
      },
    },
  },
  watch: {
    search(cur) {
      if (cur && cur[-1] === ')') {
        return;
      }
      this.fetchItems();
    },
  },
  created() {
    if (this.value.id || this.formType === 'new') {
      this.items = [this.value];
      this.isLoading = false;
    } else {
      this.initialized = true;
    }
  },
  methods: {
    fetchItems() {},
    customFilter(item, queryText) {
      const parent = item.parent.toLowerCase();
      const clientIdVcId = item.client_id.concat(' ', item.vc_id).toLowerCase();
      const name = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        parent.indexOf(searchText) > -1 ||
        name.indexOf(searchText) > -1 ||
        clientIdVcId.indexOf(searchText) > -1
      );
    },
  },
};
</script>
