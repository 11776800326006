<template>
  <nv-page :header="displayName">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
    </template>

    <v-card class="pa-4">
      <v-row dense>
        <nv-show cols="12" sm="3" header="Parent">{{ item.name }}</nv-show>
        <nv-show cols="12" sm="9" header="Country">{{ item.country_code }}</nv-show>
      </v-row>
    </v-card>

    <v-card class="mt-4 elevation-2">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab>Sub brands</v-tab>
        <v-tab>Applications</v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id" v-model="tabs">
        <v-tab-item>
          <entities-tab-pane :path="`/mv/parents/${item.id}/entities`" />
        </v-tab-item>
        <v-tab-item>
          <applications-tab-list :parent-id="item.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import EntitiesTabPane from '@/components/ddrs_entities/EntitiesTabPane';
import ApplicationsTabList from '@/components/applications/ApplicationsTabList.vue';

export default {
  components: { EntitiesTabPane, ApplicationsTabList },
  data: () => ({
    item: {},
    tabs: 0,
  }),
  computed: {
    displayName() {
      return `${this.item.name} (${this.item.country_code || 'global'})`;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/mv/parents/${this.$route.params.parentId}`).then(res => {
        this.item = res.data;
      });
    },
  },
};
</script>
