<template>
  <v-dialog :value="value" persistent max-width="1200px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12" sm="5">
          <name-selector ref="name-selector" v-model="item.name" />
        </v-col>
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="item.description"
            :rules="rules.description"
            label="Description"
            hint="The application description"
            counter="255"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="item.status"
            :disabled="canEdit"
            :rules="rules.status"
            :items="enumStatuses"
            label="Status"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-autocomplete
            ref="countryCode"
            v-model="item.country_code"
            :rules="rules.countryCode"
            :items="countries"
            item-text="name"
            item-value="code"
            label="Country"
            required
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            ref="owner"
            v-model="item.owner_id"
            :search-input.sync="ownerSearch"
            :rules="rules.ownerID"
            :items="owners"
            item-text="name"
            item-value="id"
            label="Owner"
            :disabled="!canEditOwner"
            required
          />
        </v-col>

        <v-col cols="12" sm="6">
          <sub-brand-selector
            v-model="item.ddrs_entity_id"
            :rules="rules.ddrsEntityID"
            :local-entity="item.local_ddrs_entity_id"
            form-type="edit"
            @local-input="item.local_ddrs_entity_id = $event"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="jicCode"
            v-model="item.jic_code"
            :rules="rules.jicCode"
            :items="jicCodes"
            :disabled="jicCodes.length < 2 || item.application_template_id > 0"
            label="JIC Code"
            multi-line
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            ref="ownerType"
            v-model="item.owner_type"
            :rules="rules.ownerType"
            :items="enumOwnerTypes"
            :disabled="
              !item.country_code || item.country_code !== 'US' || item.application_template_id > 0
            "
            label="Ownership"
            required
            multi-line
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="item.deployment_type"
            :rules="rules.deploymentType"
            :items="enumDeploymentTypes"
            label="Deployment"
            disabled
            multi-line
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="item.app_type"
            :rules="rules.appType"
            :items="enumAppTypes"
            label="Integration Type"
            required
            multi-line
            disabled
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="item.operating_system"
            :rules="rules.operatingSystem"
            :items="enumOperatingSystems"
            :disabled="item.application_template_id > 0"
            label="OS"
            required
            multi-line
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="item.implementation_type"
            :rules="rules.implementationType"
            :items="enumImplementationTypes"
            :disabled="item.application_template_id > 0"
            label="Implementation"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.website_url"
            :rules="rules.websiteURL"
            label="Website URL"
            hint="The application Website URL"
            counter="255"
          />
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="item.support_url"
            :rules="rules.supportURL"
            label="Support URL"
            hint="The application Support URL"
            counter="255"
          />
        </v-col>
        <v-col sm="4">
          <v-text-field
            v-model="item.store_url"
            :rules="rules.storeURL"
            label="Store URL"
            hint="The application Store URL"
            counter="255"
          />
        </v-col>
      </v-row>

      <product-edit-group
        ref="productEdit"
        v-model="item.products"
        :product-type="productType"
        :current-products="currentProducts"
        :app-template-id="item.application_template_id"
        data-cy="products"
      />

      <products-suspend-group
        v-model="item.suspended_products"
        :products="item.products"
        data-cy="suspended-products"
      />
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { updateJICCodesTypes, updateOwnerTypes, countryWatch } from '@/helpers/applications';
import { rules } from './validations';
import ProductEditGroup from '@/components/ProductEditGroup.vue';
import ProductsSuspendGroup from '@/components/ProductsSuspendGroup.vue';
import SubBrandSelector from '@/components/applications/SubBrandSelector.vue';
import NameSelector from '@/components/applications/NameSelector.vue';
import { debounce } from 'lodash';

export function debounceFetchOwner() {
  this.fetchOwners();
}

export default {
  components: {
    ProductEditGroup,
    ProductsSuspendGroup,
    SubBrandSelector,
    NameSelector,
  },
  mixins: [FormMixin],
  data: () => ({
    formContext: 'Application',
    valid: true,
    owners: [],
    enumOwnerTypes: [],
    enumAppTypes: [],
    enumImplementationTypes: [],
    enumOperatingSystems: [],
    enumStatuses: [],
    enumDeploymentTypes: [],
    countries: [],
    templates: [],
    jicCodes: [],
    template: { id: null, name: null },
    rules,
    currentProducts: [],
    ownerSearch: '',
  }),
  computed: {
    productType() {
      if (this.item?.products?.length === 1 && this.item?.products[0] === 'dar') {
        return 'campaign';
      } else if (!this.item?.products?.includes('dar')) {
        return 'content';
      }
      return null;
    },
    canEdit() {
      if (
        this.$auth.hasPermissions('dog.application_templates.readonly') &&
        this.$auth.hasPermissions('dog.applications') &&
        this.$auth.hasPermissions('dog.master_parameters.readonly') &&
        this.$auth.hasPermissions('dog.parameters') &&
        this.$auth.hasPermissions('dog.owners')
      ) {
        return false;
      }
      return true;
    },
    canEditOwner() {
      return this.$auth.hasPermissions('dog');
    },
  },
  watch: {
    countryWatch,
    ownerSearch: debounce(debounceFetchOwner, 500),
  },
  created() {
    this.enumFetch(HTTP, {
      enumOwnerTypes: '/applications/enum/owners',
      enumAppTypes: '/applications/enum/types',
      enumImplementationTypes: '/applications/enum/implementations',
      enumOperatingSystems: '/applications/enum/operating_systems',
      enumStatuses: '/applications/enum/statuses',
      enumDeploymentTypes: '/applications/enum/deployment_types',
      countries: '/countries?per_page=300&filter[status]=active',
    });
    console.log(this.countries);
    this.fetchOwners();
  },
  methods: {
    fetchOwners() {
      this.loading = true;
      HTTP.get('/owners', { params: { search: this.ownerSearch, per_page: 20 } })
        .then(res => {
          this.owners = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formReady() {
      this.owners = [{ ...this.editing.owner }];
      this.item.owner_id = this.editing.owner?.id;
      this.currentProducts = this.editing.products;
    },
    saveOwner() {
      if (this.item.owner_id) {
        this.isLoading = true;
        try {
          const response = HTTP.put(`/owners/${this.item.owner_id}/applications/update`, {
            id: this.item.id,
          });
          console.log('Data saved successfully:', response.data);
        } catch (error) {
          console.error('Error saving data:', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    updateJICCodesTypes,
    updateOwnerTypes,
    enumFetch,
    formSave() {
      this.saveOwner();
      this.item.ddrs_entity = null;
      return this.submitForm(HTTP, '/applications', {
        success: () => {
          this.$flash('success', `Application ${this.formSuccessMsg}!`);
        },
      });
    },
  },
};
</script>

<style scoped>
.v-card__title {
  padding-bottom: 0;
}
.v-card__text {
  padding: 0;
}
.v-card__actions {
  padding-top: 0;
}
.container {
  padding-bottom: 2px;
}
</style>
