<template>
  <v-card class="pa-4">
    <v-card-title>
      <span class="text-h5">Uploaded Applications</span>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="apps"
        :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
        class="elevation-1"
      >
        <template #item.id="{ item }">
          <router-link :to="appPath(item.id)">
            {{ item.id }}
          </router-link>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="secondary" @click="closeTable">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const headers = [
  { text: 'ID', sortable: false, value: 'id' },
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Description', sortable: true, value: 'description' },
];

export default {
  props: { apps: { type: Array, default: null, required: true } },
  data: () => ({
    headers,
  }),
  methods: {
    appPath(applicationId) {
      return {
        name: 'dog.applications.show',
        params: {
          applicationId,
        },
      };
    },
    closeTable() {
      this.$emit('close', false);
    },
  },
};
</script>
