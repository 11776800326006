<template>
  <span>
    <span v-if="showCurrentState">
      <kbd>{{ normBefore }}</kbd> &rarr;
    </span>
    <kbd>{{ normAfter }}</kbd>
  </span>
</template>

<script>
import HTTP from '@/api/dog';

export default {
  props: {
    type: { type: String, default: '' },
    before: { type: [String, Number], default: null },
    after: { type: [String, Number], default: null },
    showCurrentState: { type: Boolean, default: true },
  },
  data() {
    return {
      normBefore: this.before,
      normAfter: this.after,
    };
  },
  created() {
    if (this.type === 'ddrs_entity_id' || this.type === 'local_ddrs_entity_id') {
      this.valueFetch(this.before, 'before');
      this.valueFetch(this.after, 'after');
    }
  },
  methods: {
    valueFetch(cur, position) {
      if (!cur || cur === 'null' || cur === '—') {
        return;
      }
      HTTP.get(`/ddrs_entities/${cur}`).then(res => {
        const result = `${res.data.parent} / ${res.data.name} (${res.data.client_id}/${res.data.vc_id})`;
        if (position === 'before') {
          this.normBefore = result;
        } else {
          this.normAfter = result;
        }
      });
    },
  },
};
</script>
