<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
    >
      <template #item="{ item }">
        <tr>
          <td :title="item.created_at | time('long')">{{ item.created_at | time }}</td>
          <td>{{ item.event | humanize }}</td>
          <td v-placeholder>{{ item.user }}</td>
          <td>
            <ul class="py-4">
              <li v-for="(change, index) in item.changes" :key="index">
                <span class="font-weight-bold mr-1">{{ change.attribute | humanize }}:</span>
                &nbsp;
                <changelog-item
                  :before="JSON.stringify(change.before)"
                  :after="JSON.stringify(change.after)"
                />
              </li>
            </ul>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import ChangelogItem from '@/components/ChangelogItem.vue';

const headers = [
  { text: 'Timestamp', sortable: false, value: 'created_at' },
  { text: 'Event', sortable: false, value: 'event' },
  { text: 'User', sortable: false, value: 'user' },
  { text: 'Changes', sortable: false, value: 'changes' },
];

export default {
  components: { ChangelogItem },
  mixins: [TableMixin],
  props: { ownerId: { type: Number, required: true } },
  data: () => ({
    headers,
    tableParam: 'history.dt',
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/owners/${this.ownerId}/history`);
    },
  },
};
</script>
