<template>
  <v-text-field
    ref="name"
    :value="value"
    :rules="rules.applicationName"
    label="Name"
    hint="The application name"
    counter="255"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { rules } from './validations';

export default {
  props: {
    value: { type: String, default: '' },
  },
  data: () => ({
    rules,
  }),
};
</script>
