<template>
  <v-col cols="12">
    <v-select
      ref="encoding"
      :value="value"
      :rules="rules.encoding"
      :items="encodingTypes"
      :label="label"
      :disabled="disabled"
      data-cy="encoding-select"
      @input="$emit('input', $event)"
    />
  </v-col>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export default {
  props: {
    value: { type: String, default: '' },
    master: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    rules,
    encodingTypes: [],
    label: 'Encoding',
    enumFetch,
  }),
  created() {
    this.label = this.master ? 'Default Encoding' : 'Encoding';
    this.enumFetch(HTTP, {
      encodingTypes: '/parameters/enum/encodings',
    });
  },
};
</script>
