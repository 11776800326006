<template>
  <v-dialog :value="value" persistent max-width="1200px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="new-custom-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12" sm="5">
          <name-selector ref="name-selector" v-model="item.name" />
        </v-col>

        <v-col cols="12" sm="5">
          <v-text-field
            v-model="item.description"
            :rules="rules.description"
            label="Description"
            hint="The application description"
            counter="255"
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-select
            ref="status"
            v-model="item.status"
            :rules="rules.status"
            :items="enumStatuses"
            label="Status"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-autocomplete
            ref="countryCode"
            v-model="item.country_code"
            :rules="rules.countryCode"
            :items="countries"
            item-text="name"
            item-value="code"
            label="Country"
            required
          />
        </v-col>

        <v-col cols="12" sm="3">
          <nv-show v-if="ownerId" header="Owner">
            {{ owner && owner.name }}
          </nv-show>
          <owner-search v-else v-model="item.owner_id" />
        </v-col>

        <v-col cols="12" sm="7">
          <sub-brand-selector v-model="item.ddrs_entity_id" />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="jicCode"
            v-model="item.jic_code"
            :rules="rules.jicCode"
            :items="jicCodes"
            :disabled="jicCodes.length < 2"
            label="JIC Code"
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="ownerType"
            v-model="item.owner_type"
            :rules="rules.ownerType"
            :items="ownerTypes"
            :disabled="!item.country_code || item.country_code !== 'US'"
            label="Ownership"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="deploymentType"
            v-model="item.deployment_type"
            :rules="rules.deploymentType"
            :items="enumDeploymentTypes"
            label="Deployment"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            ref="appType"
            v-model="item.app_type"
            :rules="rules.appType"
            :items="appTypes"
            label="Integration Type"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            ref="operatingSystem"
            v-model="item.operating_system"
            :rules="rules.operatingSystem"
            :items="enumOperatingSystems"
            label="OS"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            ref="implementationType"
            v-model="item.implementation_type"
            :rules="rules.implementationType"
            :items="enumImplementationTypes"
            label="Implementation"
            required
            multi-line
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.website_url"
            :rules="rules.websiteURL"
            label="Website URL"
            hint="The application Website URL"
            counter="255"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.support_url"
            :rules="rules.supportURL"
            label="Support URL"
            hint="The application Support URL"
            counter="255"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.store_url"
            :rules="rules.storeURL"
            label="Store URL"
            hint="The application Store URL"
            counter="255"
          />
        </v-col>
      </v-row>

      <product-edit-group v-model="item.products" :product-type="productType" />
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { updateJICCodesTypes, updateOwnerTypes, countryWatch } from '@/helpers/applications';
import { rules } from './validations';
import OwnerSearch from '@/components/applications/OwnerSearch.vue';
import SubBrandSelector from '@/components/applications/SubBrandSelector.vue';
import NameSelector from '@/components/applications/NameSelector.vue';
import ProductEditGroup from '@/components/ProductEditGroup.vue';

export default {
  components: {
    OwnerSearch,
    ProductEditGroup,
    SubBrandSelector,
    NameSelector,
  },
  mixins: [FormMixin],
  props: {
    ownerId: { type: Number, default: null },
    productType: { type: String, default: null },
  },
  data: () => ({
    formContext: 'Application',
    rules,
    owner: null,
    owners: [],
    allOwnerTypes: [],
    ownerTypes: [],
    enumAppTypes: [],
    enumImplementationTypes: [],
    enumOperatingSystems: [],
    enumStatuses: [],
    enumDeploymentTypes: [],
    countries: [],
    jicCodes: [],
  }),
  computed: {
    appTypes() {
      return this.enumAppTypes.filter(
        t =>
          t.value !== 'ad_network' || (t.value === 'ad_network' && this.productType !== 'content'),
      );
    },
  },
  watch: countryWatch(),
  created() {
    this.fetchData();
    this.enumFetch(HTTP, {
      enumAppTypes: '/applications/enum/types',
      enumImplementationTypes: '/applications/enum/implementations',
      enumOperatingSystems: '/applications/enum/operating_systems',
      enumStatuses: '/applications/enum/statuses',
      enumDeploymentTypes: '/applications/enum/deployment_types',
      countries: '/countries?per_page=300&filter[status]=active',
    });
  },
  methods: {
    fetchData() {
      if (this.ownerId) {
        HTTP.get(`/owners/${this.ownerId}`).then(res => {
          this.owner = res.data;
        });
      }
    },
    updateJICCodesTypes,
    updateOwnerTypes,
    enumFetch,
    formSave() {
      const url = this.ownerId
        ? `/owners/${this.ownerId}/applications/custom`
        : `/owners/${this.item.owner_id}/applications/custom`;
      return this.submitForm(HTTP, url, {
        success: res => {
          this.$flash('success', `Application ${this.formSuccessMsg}!`);
          this.$router.push({
            name: 'dog.applications.show',
            params: { applicationId: res.data.id },
          });
        },
      });
    },
  },
};
</script>
