<template>
  <v-menu v-if="$auth.hasPermissions('dog.applications')" offset-y>
    <template #activator="{ on }">
      <v-btn color="secondary" v-on="on">
        <v-icon class="mr-1">mdi-chevron-down</v-icon>
        Add
      </v-btn>
    </template>
    <v-list data-cy="add-menu">
      <v-list-item
        v-if="available.includes('template')"
        data-cy="add-menu-template"
        @click="$emit('selected', 'template')"
      >
        <v-list-item-title>
          From Template
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="available.includes('custom') && $auth.hasPermissions('dog.custom_applications')"
        data-cy="add-menu-custom"
        @click="$emit('selected', 'custom')"
      >
        <v-list-item-title>
          Custom
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="available.includes('bulk')"
        data-cy="add-menu-bulk"
        @click="$emit('selected', 'bulk')"
      >
        <v-list-item-title>
          Bulk
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: { available: { type: Array, default: null } },
};
</script>
