<template>
  <v-col cols="12" md="4">
    <v-btn-toggle v-model="filters.state" mandatory>
      <v-btn v-for="item in enumStates" :key="item" :value="item" text small>
        {{ item | humanize }}
      </v-btn>
    </v-btn-toggle>
  </v-col>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';

export default {
  name: 'ScheduledChangesStateFilter',
  mixins: [FiltersMixin],
  props: { value: { type: String, default: 'pending' } },
  data: () => ({
    enumStates: ['pending', 'applied', 'all'],
    filters: { state: 'pending' },
    filtersParam: 'changes.ft',
  }),
};
</script>
