import {
  ComponentsPlugin,
  FlashPlugin,
  FiltersPlugin,
  PlaceholderPlugin,
  inflections,
} from '@nielsen-media/ui-toolkit-vue';
import '@nielsen-media/ui-toolkit-vue/lib/ui-toolkit-vue.css';
import Vue from 'vue';

Vue.use(ComponentsPlugin);
Vue.use(FiltersPlugin, { locale: 'en-US' });
Vue.use(PlaceholderPlugin);
Vue.use(FlashPlugin);

// TODO: add your inflections
inflections('en', function(i) {
  i.human('ddrs_entity_id', 'Client id/Sub brand');
  i.human('base', 'Record');
  i.human('nil', '-');
  i.acronym('ID');
  i.acronym('SDK');
  i.acronym('JIC');
  i.acronym('CMS');
  i.acronym('VRI');
  i.acronym('AGF');
  i.acronym('TV');
  i.acronym('VA');
  i.acronym('FB');
});
