<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      ref="niForm"
      :errors="formErrors"
      :loading="loading"
      title="Schedule Application Change"
      @cancel="formCancel"
      @nv-submit="submit"
    >
      <v-row dense>
        <v-col cols="12">
          <sub-brand-selector
            :value="data.ddrs_entity_id"
            @input="data.ddrs_entity_id = $event"
            @local-input="data.local_ddrs_entity_id = $event"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field
                v-model="date"
                label="Scheduled Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                data-cy="scheduled-date-picker-activator"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="date" :min="tomorrow" />
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select v-model="data.syndicated" :items="enumSyndicated" label="Syndicated" />
        </v-col>
        <v-spacer />
      </v-row>
      <nv-confirm ref="confirm" />
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import SubBrandSelector from '@/components/applications/SubBrandSelector.vue';

export default {
  components: {
    SubBrandSelector,
  },
  mixins: [FormMixin],
  props: { applicationId: { type: String, required: true } },
  data: () => ({
    date: null,
    valid: true,
    data: {},
    enumSyndicated: [],
    confirmDialogShow: false,
  }),
  computed: {
    tomorrow() {
      const today = new Date();
      const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      return tomorrow.toISOString().substr(0, 10);
    },
  },
  created() {
    this.enumFetch(HTTP, {
      enumSyndicated: '/applications/enum/syndicated',
    });
  },
  methods: {
    formReady() {
      this.data = {};
      this.date = null;
    },
    isStartOfMonth(date) {
      const dateObject = new Date(date);
      return dateObject.getDate() === 1;
    },
    submit(form) {
      if (this.data.ddrs_entity_id === 0) this.data.ddrs_entity_id = null;
      if (this.data.local_ddrs_entity_id === 0) this.data.local_ddrs_entity_id = null;
      if (this.date && this.date.constructor === String && !this.isStartOfMonth(this.date)) {
        this.$refs.confirm
          .open(
            'Add Scheduled Change',
            'Scheduled Change date is not the start of a month. Are you sure?',
          )
          .then(() => {
            this.formSubmit(form);
          });
      } else {
        this.formSubmit(form);
      }
    },
    formSave() {
      const changes = [];
      const allowedAttrs = ['ddrs_entity_id', 'local_ddrs_entity_id', 'syndicated'];
      Object.keys(this.data).forEach(key => {
        if (allowedAttrs.includes(key) && this.data[key] != null) {
          changes.push({ attribute_name: key, after: this.data[key] });
        }
      });
      this.item = {
        scheduled_on: this.date,
        proposed_changes: changes,
      };

      return this.submitForm(HTTP, `/applications/${this.applicationId}/scheduled_changes`, {
        success: () => {
          this.$flash('success', 'Application updated!');
        },
      });
    },
    enumFetch,
  },
};
</script>
