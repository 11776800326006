<template>
  <v-row dense justify="center" align="center">
    <v-col sm="3" cols="12">
      <span class="text-caption mr-1">Status:</span>&nbsp;
      <v-btn-toggle v-model="filters.status">
        <v-btn v-for="item in enumOwnerStatuses" :key="item.value" :value="item.value" text small>
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col sm="4" cols="12">
      <span class="text-caption mr-1">Publisher:</span>&nbsp;
      <v-btn-toggle v-model="filters.publisher_type">
        <v-btn v-for="item in enumPublisherTypes" :key="item.value" :value="item.value" text small>
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col sm="3" cols="12">
      <v-autocomplete
        v-model="filters.country_code"
        :items="countries"
        item-text="name"
        item-value="code"
        clearable
        label="Country"
      />
    </v-col>
  </v-row>
</template>

<script>
import HTTP from '@/api/dog';
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
      country_code: null,
      publisher_type: null,
    },
    countries: [],
    enumOwnerStatuses: [],
    enumPublisherTypes: [],
    enumFetch,
  }),
  created() {
    this.enumFetch(HTTP, {
      enumPublisherTypes: '/owners/enum/publisher_types',
      countries: '/countries?per_page=300',
      enumOwnerStatuses: '/owners/enum/statuses',
    });
  },
};
</script>
