<template>
  <list title="Content Applications" product-type="content" />
</template>

<script>
import List from './List.vue';

export default {
  components: { List },
};
</script>
