<template>
  <v-autocomplete
    ref="ownerId"
    :value="value"
    :rules="rules.ownerID"
    :items="owners"
    :search-input.sync="ownerSearch"
    :loading="loading"
    data-cy="owner-autocomplete"
    item-text="name"
    item-value="id"
    no-filter
    label="Owner"
    required
    @input="$emit('input', $event)"
  />
</template>

<script>
import { debounce } from 'lodash';
import HTTP from '@/api/dog';
import { rules } from './validations';

export function fetchOwners() {
  this.loading = true;
  HTTP.get('/owners', { params: { search: this.ownerSearch, per_page: 20 } })
    .then(res => {
      this.owners = res.data;
    })
    .finally(() => {
      this.loading = false;
    });
}

export default {
  props: { value: { type: Number, default: null } },
  data: () => ({
    rules,
    loading: false,
    ownerSearch: '',
    owners: [],
  }),
  watch: {
    ownerSearch: debounce(fetchOwners, 500),
  },
};
</script>
