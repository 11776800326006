<template>
  <v-container>
    <v-row justify="center" align="center">
      <span class="text-caption mr-2">Status:</span>&nbsp;
      <v-btn-toggle v-model="filters.status">
        <v-btn v-for="item in enumParamStatuses" :key="item.value" :value="item.value" text small>
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </v-row>
  </v-container>
</template>

<script>
import HTTP from '@/api/dog';
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
    },
    enumParamStatuses: [],
    enumFetch,
    filtersParam: 'sdk.ft',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumParamStatuses: '/parameters/enum/statuses',
    });
  },
};
</script>
