<template>
  <v-dialog :value="value" persistent max-width="800px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Select Sub-Brands</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <global-sub-brand-picker v-model="subBrand" />
          <local-sub-brand-picker v-model="localSubBrand" :global-sub-brand="subBrand.id" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" data-cy="sub-brand-dialog-close" @click="closeDialog">Close</v-btn>
        <v-btn
          :disabled="isSubmitDisabled"
          color="secondary"
          data-cy="sub-brand-submit"
          class="submit"
          @click="submit"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GlobalSubBrandPicker from '@/components/applications/sub_brand_selector/GlobalSubBrandPicker.vue';
import LocalSubBrandPicker from '@/components/applications/sub_brand_selector/LocalSubBrandPicker.vue';

export default {
  components: { GlobalSubBrandPicker, LocalSubBrandPicker },
  props: {
    value: { type: Boolean, default: false },
    ddrsEntity: { type: Object, default: null },
    localEntity: { type: Object, default: null },
    selectorFormType: { type: String, required: true },
  },
  data: () => ({
    subBrand: {},
    localSubBrand: {},
  }),
  computed: {
    isSubmitDisabled() {
      return !this.subBrand.id;
    },
  },
  watch: {
    ddrsEntity(cur) {
      if (cur && this.selectorFormType !== 'new') {
        this.subBrand = cur;
      }
    },
    localEntity(cur) {
      if (cur) {
        this.localSubBrand = cur;
      }
    },
  },
  methods: {
    submit() {
      if (this.subBrand.id) {
        this.$emit('ddrs-entity', this.subBrand.id);
      } else {
        this.$emit('ddrs-entity', null);
      }
      if (this.localSubBrand.id) {
        this.$emit('local-entity', this.localSubBrand.id);
      } else {
        this.$emit('local-entity', null);
      }
      this.closeDialog();
    },
    closeDialog() {
      if (this.selectorFormType === 'new') {
        this.$refs.form.reset();
      }
      this.$emit('input', false);
    },
  },
};
</script>
