<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="item.name"
            :rules="rules.name"
            :disabled="!!editing"
            data-cy="sdk-name"
            label="Name"
            hint="The parameter name"
            counter="128"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            ref="value"
            v-model="item.value"
            :rules="rules.value"
            label="Value"
            hint="The parameter value"
            counter="400"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            ref="description"
            v-model="item.description"
            :rules="rules.description"
            label="Description"
            hint="A helpful description"
            counter="255"
          />
        </v-col>
        <v-col v-if="!!editing" cols="12" sm="6">
          <v-select
            v-model="item.status"
            :rules="rules.status"
            :items="enumSDKStatuses"
            label="Status"
            required
            multi-line
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export default {
  mixins: [FormMixin],
  props: {
    applicationId: { type: String, default: null },
    templateId: { type: [Number, String], default: null },
    appCount: { type: Number, default: 0 },
  },
  data: () => ({
    product: {},
    enumSDKStatuses: [],
    rules,
    formContext: 'SDK Configuration',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumSDKStatuses: '/sdks/configuration/enum/statuses',
    });
  },
  methods: {
    formReady() {
      if (!this.applicationId) {
        this.originalItem = Object.assign({}, this.item);
      }
    },
    formSave() {
      let path = '';
      if (this.applicationId) {
        path = this.editing
          ? '/sdk_configurations'
          : `/applications/${this.applicationId}/sdk_configurations`;
        return this.submitForm(HTTP, path, {
          success: res => {
            this.$flash('success', `SDK Configuration ${res.data.name} ${this.formSuccessMsg}!`);
          },
        });
      } else {
        path = this.editing
          ? 'applications/templates/sdk_configurations'
          : `/applications/templates/${this.templateId}/sdk_configurations`;
        if (this.appCount === 0) {
          return this.submitForm(HTTP, path, {
            success: res => {
              this.$flash('success', `SDK Configuration ${res.data.name} ${this.formSuccessMsg}!`);
            },
          });
        }
      }
    },
    enumFetch,
  },
};
</script>
