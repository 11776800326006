import vrb from 'bsm-validation-rules';
import { types } from '@/helpers/validations';

export const rules = {
  applicationName: [vrb.presence(), ...types.name],
  templateName: [vrb.presence(), ...types.string(100)],
  description: [...types.description],
  status: [vrb.presence(), vrb.typeOf('string')],
  appType: [vrb.presence(), vrb.typeOf('string')],
  countryCode: [vrb.presence(), ...types.countryCode],
  implementationType: [vrb.presence(), vrb.typeOf('string')],
  jicCode: [...types.jicCode],
  operatingSystem: [vrb.presence(), vrb.typeOf('string')],
  ownerType: [vrb.presence(), vrb.typeOf('string')],
  websiteURL: [...types.url],
  supportURL: [...types.url],
  storeURL: [...types.url],
  ownerID: [vrb.presence(), vrb.typeOf('integer')],
  ddrsEntityID: [vrb.typeOf('integer')],
  templateID: [vrb.presence(), vrb.typeOf('integer')],
  bulkFile: [vrb.presence()],
  firstPartyIDTTL: [vrb.typeOf('integer'), ...types.range(0, 393)],
};
