<template>
  <v-dialog :value="value" persistent max-width="1200px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      :title="formTitle"
      data-cy="templates-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12" sm="8">
          <v-text-field
            ref="name"
            v-model="item.name"
            :rules="rules.templateName"
            label="Name"
            hint="The template name"
            counter="50"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-autocomplete
            ref="countryCode"
            v-model="item.country_code"
            :rules="rules.countryCode"
            :items="countries"
            item-text="name"
            item-value="code"
            label="Country"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            ref="jicCode"
            v-model="item.jic_code"
            :rules="rules.jicCode"
            :items="jicCodes"
            :disabled="jicCodes.length < 2"
            label="JIC Code"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            ref="ownerType"
            v-model="item.owner_type"
            :rules="rules.ownerType"
            :items="ownerTypes"
            :disabled="!item.country_code || item.country_code !== 'US'"
            label="Ownership"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="appType"
            v-model="item.app_type"
            :rules="rules.appType"
            :items="appTypes"
            :disabled="!!editing"
            label="Integration Type"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="operatingSystem"
            v-model="item.operating_system"
            :rules="rules.operatingSystem"
            :items="operatingSystems"
            label="OS"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="implementationType"
            v-model="item.implementation_type"
            :rules="rules.implementationType"
            :items="implementationTypes"
            label="Implementation"
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-select
            v-if="!!editing && item.applications_count === 0"
            v-model="item.status"
            :rules="rules.status"
            :items="statuses"
            label="Status"
          />
        </v-col>
      </v-row>

      <product-edit-group v-model="item.products" />
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { updateJICCodesTypes, updateOwnerTypes, countryWatch } from '@/helpers/applications';
import { rules } from '@/components/applications/validations';
import ProductEditGroup from '@/components/ProductEditGroup.vue';

export default {
  components: { ProductEditGroup },
  mixins: [FormMixin],
  data: () => ({
    rules,
    owners: [],
    allOwnerTypes: [],
    ownerTypes: [],
    allAppTypes: [],
    implementationTypes: [],
    operatingSystems: [],
    statuses: [],
    countries: [],
    jicCodes: [],
    formContext: 'Application Template',
  }),
  computed: {
    appTypes() {
      return this.allAppTypes.filter(
        t => t.value !== 'ad_network' || this.item.products?.includes('dar'),
      );
    },
  },
  watch: countryWatch({}),
  created() {
    this.enumFetch(HTTP, {
      allAppTypes: '/applications/enum/types',
      implementationTypes: '/applications/enum/implementations',
      operatingSystems: '/applications/enum/operating_systems',
      statuses: '/applications/enum/statuses',
      countries: '/countries?per_page=300&filter[status]=active',
    });
  },
  methods: {
    updateJICCodesTypes,
    updateOwnerTypes,
    enumFetch,
    formSave() {
      return this.submitForm(HTTP, '/applications/templates', {
        success: res => {
          this.$flash('success', `Template ${this.formSuccessMsg}!`);
          if (!this.editing) {
            this.$router.push({
              name: 'dog.applications.templates.show',
              params: { templateId: res.data.id },
            });
          }
        },
      });
    },
  },
};
</script>
