<template>
  <v-container>
    <v-row align="end" justify="center" dense>
      <v-col sm="4" cols="12" class="text-center">
        <span class="text-caption mr-2">Status:</span>&nbsp;
        <v-btn-toggle v-model="filters.status">
          <v-btn v-for="item in enumParamStatuses" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col sm="4" cols="8">
        <v-select
          ref="encoding"
          v-model="filters.encoding"
          :items="enumParamEncodings"
          clearable
          label="Encoding"
          hide-details
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HTTP from '@/api/dog';
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
      encoding: null,
    },
    enumParamStatuses: [],
    enumParamEncodings: [],
    enumFetch,
    filtersParam: 'params.ft',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumParamStatuses: '/parameters/enum/statuses',
      enumParamEncodings: '/parameters/enum/encodings',
    });
  },
};
</script>
