<template>
  <nv-tab-page>
    <template slot="toolbar">
      <v-btn
        v-if="editConfigButtonVisible"
        ref="edit-button"
        :disabled="!edit"
        data-cy="configuration-flags-edit"
        color="secondary"
        @click="editConfigDisabled = false"
      >
        <v-icon class="mr-1">mdi-pencil</v-icon>
        Edit
      </v-btn>
      <template v-else-if="edit && !editConfigDisabled">
        <v-btn
          ref="save-button"
          data-cy="configuration-flags-save"
          color="secondary"
          @click="saveConfig"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn
          ref="cancel-button"
          data-cy="configuration-flags-cancel"
          color="primary"
          @click.native.stop="resetConfig"
        >
          <v-icon class="mr-1">mdi-close-circle</v-icon>
          Cancel
        </v-btn>
      </template>
    </template>
    <v-row align="end">
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.dar_report"
          :items="enumDarReports"
          :disabled="editConfigDisabled"
          label="DAR Report"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.syndicated"
          :items="enumSyndicated"
          :disabled="editConfigDisabled"
          label="Syndicated"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          ref="userOptOut"
          v-model="editableValue.user_opt_out"
          :disabled="editConfigDisabled"
          :items="userOptOut"
          :clearable="!editConfigDisabled"
          data-cy="configuration-flags-uoo"
          label="DAR Optout Value"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.sync_providers"
          :disabled="editConfigDisabled"
          :items="enumSyncProviders"
          :clearable="!editConfigDisabled"
          chips
          deletable-chips
          item-text="name"
          item-value="code"
          label="Sync Providers"
          multiple
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.debug_sf_code"
          :disabled="editConfigDisabled"
          :items="enumSFCodes"
          data-cy="debug_sf_code"
          clearable
          label="Debug SF Code"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model.number="editableValue.first_party_id_ttl"
          :disabled="editConfigDisabled"
          data-cy="first_party_id_ttl"
          type="number"
          :rules="rules.firstPartyIDTTL"
          label="First Party ID TTL"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.sivt_viewabilities"
          :disabled="editConfigDisabled"
          :items="enumSIVTViewabilities"
          :clearable="!editConfigDisabled"
          chips
          deletable-chips
          item-text="name"
          item-value="code"
          label="Viewability"
          multiple
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.sivt_products"
          :disabled="editConfigDisabled"
          :items="enumSIVTProducts"
          :clearable="!editConfigDisabled"
          chips
          deletable-chips
          item-text="name"
          item-value="code"
          label="SIVT Products"
          multiple
        />
        <!-- check how this works-->
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="editableValue.dom_less_env"
          :items="enumDomLessEnv"
          :disabled="editConfigDisabled"
          :clearable="!editConfigDisabled"
          label="DOM Less Environment"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.sdk_user_optout"
          :readonly="editConfigDisabled"
          data-cy="browser_sdk_user_optout"
          label="SDK User Optout"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.cookieless_domain_enabled"
          :readonly="editConfigDisabled"
          data-cy="browser_cookieless_domain"
          label="Browser Cookieless Domain"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.first_party_id_enabled"
          :readonly="editConfigDisabled"
          data-cy="first_party_id"
          label="First Party ID"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.hash_vendor_id_enabled"
          :readonly="editConfigDisabled"
          data-cy="hash_vendor_id"
          label="Hash Vendor ID"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.vendor_id_enabled"
          :readonly="editConfigDisabled"
          data-cy="vendor_id"
          label="Vendor ID"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.emm_enabled"
          :readonly="editConfigDisabled"
          data-cy="emm-enabled"
          label="EMM"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.sivt_enabled"
          :readonly="editConfigDisabled"
          data-cy="sivt-enabled"
          label="SIVT"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.hashed_email_enabled"
          :readonly="editConfigDisabled"
          data-cy="hashed-email-enabled"
          label="Hashed Email"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.static_duration_enabled"
          :readonly="editConfigDisabled"
          data-cy="static-duration-enabled"
          label="Static Duration"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.sdk_diagnostic_data_enabled"
          :readonly="editConfigDisabled"
          data-cy="sdk-diagnostic-data-enabled"
          label="SDK Diagnostic Data"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.uid2_enabled"
          :readonly="editConfigDisabled"
          data-cy="uid2-enabled"
          label="UID2"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.cms"
          :readonly="editConfigDisabled"
          data-cy="cms"
          label="CMS"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.ad_measurement"
          :readonly="editConfigDisabled"
          label="Ad Measurement"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.va_beacon"
          :readonly="editConfigDisabled"
          label="VA Beacon"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.multi_lingual_cms"
          :readonly="editConfigDisabled"
          label="Multi Lingual CMS"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.video_census"
          :readonly="editConfigDisabled"
          label="Video Census"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.legacy_ping"
          :readonly="editConfigDisabled"
          label="Legacy Ping"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.agf_identity"
          :readonly="editConfigDisabled"
          label="AGF Identity"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch v-model="editableValue.vri" :readonly="editConfigDisabled" label="VRI" />
      </v-col>
      <v-col cols="12" sm="4">
        <v-switch
          v-model="editableValue.metadata_ping_enabled"
          :readonly="editConfigDisabled"
          label="Metadata Ping"
        />
      </v-col>
    </v-row>
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export default {
  props: {
    value: { type: Object, default: null, required: true },
    isTemplate: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: true },
  },
  data: () => ({
    editableValue: {},
    enumDarReports: [],
    enumSyndicated: [],
    enumSyncProviders: [],
    enumSIVTViewabilities: [],
    enumSIVTProducts: [],
    enumDomLessEnv: [],
    enumSFCodes: ['cert', 'eu_uat'], // TODO: Replace with enum call on API
    editConfigDisabled: true,
    rules,
    userOptOut: ['1', 'true', 'yes'],
  }),
  computed: {
    editConfigButtonVisible() {
      if (this.canEdit) {
        return this.editConfigDisabled;
      }

      return false;
    },
    canEdit() {
      // Templates
      if (this.isTemplate) {
        if (this.value.applications_count === 0) {
          return this.$auth.hasPermissions('dog.application_templates');
        }
        return false;
      }
      // Applications
      if (this.value.row_version > 0) {
        return this.$auth.hasPermissions('dog.applications');
      }
      return false;
    },
    edit() {
      if (!this.isEditable) {
        return false;
      }
      return this.canEdit;
    },
  },
  watch: {
    value() {
      this.resetEditableValue();
    },
  },
  created() {
    this.resetEditableValue();
    this.enumFetch(HTTP, {
      enumDarReports: '/applications/enum/dar_reports',
      enumSyndicated: '/applications/enum/syndicated',
      enumSyncProviders: '/applications/enum/sync_providers',
      enumSIVTViewabilities: '/applications/enum/viewabilities',
      enumSIVTProducts: '/applications/enum/sivt',
      enumDomLessEnv: '/applications/enum/dom_less_env',
    });
  },
  methods: {
    resetEditableValue() {
      if (this.value == null) {
        this.editableValue = Object.assign({}, 'null');
      }
      this.editableValue = Object.assign({}, this.value);
    },
    remove(item) {
      this.editableValue.sync_providers.splice(this.editableValue.sync_providers.indexOf(item), 1);
    },
    saveConfig() {
      this.editConfigDisabled = true;
      this.$emit('input', this.editableValue);
    },
    resetConfig() {
      this.resetEditableValue();
      this.editConfigDisabled = true;
    },
    enumFetch,
  },
};
</script>
