<template>
  <nv-page header="Application Templates">
    <template slot="toolbar">
      <nv-btn
        v-if="$auth.hasPermissions('dog.application_templates')"
        type="add"
        @click="form.show = true"
      />
      <v-spacer class="hidden-sm-and-down" />

      <nv-table-search v-model="search" class="mr-0 hidden-xs-only" />
    </template>
    <template slot="filters">
      <nv-table-filters
        ref="applied-filters"
        :value="formattedFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet
          ref="filter-sheet"
          :product-type="settings.product_type"
          @changed="setTableFilters"
        />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="templatePath(item.id)">
              {{ item.name }}
            </router-link>
          </td>
          <td>{{ item.applications_count }}</td>
          <td>{{ item.syndicated | enumLabel(syndicated) }}</td>
          <td>{{ item.status | humanize }}</td>
        </tr>
      </template>
    </v-data-table>

    <edit-form v-model="form.show" :product-type="productType" @saved="tableFetch" />
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { formatFilters } from '@/helpers/filters';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import EditForm from '@/components/application_templates/EditForm.vue';
import FilterSheet from '@/components/application_templates/FilterSheet.vue';
import store from 'store';

const fns = store.namespace('filters');

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Applications', sortable: false, value: 'applications_count' },
  { text: 'Syndication', sortable: false, value: 'syndicated' },
  { text: 'Status', sortable: false, value: 'status' },
];
export default {
  components: { EditForm, FilterSheet },
  mixins: [TableMixin],
  data: () => ({
    headers,
    form: { show: false },
    productType: 'apptemplates',
    syndicated: [],
    countries: [],
  }),
  computed: {
    formattedFilters() {
      return formatFilters(this.tableFilters, this.countries);
    },
  },
  created() {
    this.enumFetch(HTTP, {
      countries: '/countries?per_page=300&filter[status]=active',
      syndicated: '/applications/enum/syndicated',
    });
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/applications/templates');
    },
    templatePath(templateId) {
      return {
        name: 'dog.applications.templates.show',
        params: { templateId },
      };
    },
    selectedProductType(productType) {
      this.$router.push(`/applications/${productType}`);
    },
    enumFetch,
    formatFilters,
    setTableFilters(filters) {
      fns.set('templates', filters);
      this.tableFilters = { ...filters };
    },
  },
};
</script>
