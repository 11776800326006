<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="value"
            v-model="item.value"
            :rules="rules.value"
            :disabled="item.read_only"
            data-cy="value-text"
            label="Value"
            hint="The parameter value"
            counter="255"
            required
          />
        </v-col>

        <encoding-select ref="encoding" v-model="item.encoding" :disabled="item.read_only" />

        <v-col cols="12" sm="4">
          <v-select
            v-model="item.status"
            :rules="rules.status"
            :items="enumParamStatuses"
            label="Status"
            required
            multi-line
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import EncodingSelect from '@/components/master_parameters/EncodingSelect.vue';
import { rules } from './validations';

export default {
  components: { EncodingSelect },
  mixins: [FormMixin],
  props: {
    applicationId: { type: String, default: null },
    appCount: { type: Number, default: 0 },
  },
  data: () => ({
    product: {},
    enumParamStatuses: [],
    rules,
    formContext: 'Parameter',
    originalItem: {},
  }),
  created() {
    this.enumFetch(HTTP, {
      enumParamStatuses: '/parameters/enum/statuses',
    });
  },
  methods: {
    formReady() {
      if (!this.applicationId) {
        this.originalItem = Object.assign({}, this.item);
      }
    },
    formSave() {
      if (this.applicationId) {
        return this.submitForm(HTTP, '/parameters', {
          success: res => {
            this.$flash(
              'success',
              `Parameter ${res.data.master_parameter?.name} ${this.formSuccessMsg}!`,
            );
          },
        });
      } else {
        if (this.appCount === 0) {
          return this.submitForm(HTTP, '/applications/templates/parameters', {
            success: res => {
              this.$flash(
                'success',
                `Parameter ${res.data.master_parameter?.name} ${this.formSuccessMsg}!`,
              );
            },
          });
        }
      }
    },
    enumFetch,
  },
};
</script>
