<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      :title="formTitle"
      data-cy="owners-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="item.name"
            :rules="rules.name"
            label="Name"
            hint="The owner name"
            counter="100"
          />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            ref="countryCode"
            v-model="item.country_code"
            :rules="rules.countryCode"
            :items="countries"
            item-text="name"
            item-value="code"
            label="Country"
          />
        </v-col>

        <v-col cols="12">
          <v-select
            ref="publisher"
            v-model="item.publisher_type"
            :rules="rules.publisherType"
            :items="enumPublisherTypes"
            label="Publisher Type"
          />
        </v-col>

        <v-col v-if="!!editing" cols="12">
          <v-select
            ref="status"
            v-model="item.status"
            :disabled="item.active_apps_count > 0"
            :rules="rules.status"
            :items="enumOwnerStatuses"
            label="Status"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export default {
  mixins: [FormMixin],
  data: () => ({
    formContext: 'Owner',
    rules,
    enumOwnerStatuses: [],
    countries: [],
    enumPublisherTypes: [],
  }),
  created() {
    this.enumFetch(HTTP, {
      enumOwnerStatuses: '/owners/enum/statuses',
      enumPublisherTypes: '/owners/enum/publisher_types',
      countries: '/countries?per_page=300&filter[status]=active',
    });
  },
  methods: {
    formSave() {
      return this.submitForm(HTTP, '/owners', {
        success: res => {
          this.$flash('success', `Owner ${res.data.name} ${this.formSuccessMsg}!`);
          if (!this.editing) {
            this.$router.push({
              name: 'dog.owners.show',
              params: { ownerId: res.data.id },
            });
          }
        },
      });
    },
    enumFetch,
  },
};
</script>
