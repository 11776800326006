<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      :title="formTitle"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="name"
            v-model="item.name"
            :rules="rules.name"
            :disabled="!!editing"
            label="Key Name"
            hint="The parameter key name"
            counter="128"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-select
            ref="parameterType"
            v-model="item.parameter_type"
            :rules="rules.parameterType"
            :items="enumParameterTypes"
            :disabled="!!editing"
            label="Type"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            ref="defaultValue"
            v-model="item.default_value"
            :rules="rules.defaultValue"
            :disabled="!!editing"
            label="Default Value"
            hint="The default parameter value"
            counter="255"
          />
        </v-col>

        <encoding-select v-model="item.encoding" :disabled="!!editing" master />

        <v-col cols="12">
          <v-text-field
            ref="description"
            v-model="item.description"
            :rules="rules.description"
            :disabled="!!editing"
            label="Description"
            hint="A helpful description"
            counter="255"
          />
        </v-col>
      </v-row>

      <product-edit-group v-if="value" v-model="item.products" :disabled="!!editing" />
      <v-row>
        <v-col v-if="!!editing" cols="12" sm="4">
          <v-select
            v-model="item.status"
            :rules="rules.status"
            :items="enumParamStatuses"
            label="Status"
          />
        </v-col>

        <v-col cols="12" :offset-sm="editing ? 1 : 0" sm="4">
          <v-switch
            ref="read_only_switch"
            v-model="item.read_only"
            :rules="rules.readOnly"
            label="Read Only"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';
import EncodingSelect from '@/components/master_parameters/EncodingSelect.vue';
import ProductEditGroup from '@/components/ProductEditGroup.vue';

export default {
  components: {
    EncodingSelect,
    ProductEditGroup,
  },
  mixins: [FormMixin],
  data: () => ({
    rules,
    enumParameterTypes: [],
    enumParamStatuses: [],
    formContext: 'Master Parameter',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumParameterTypes: '/parameters/enum/types',
      enumParamStatuses: '/parameters/enum/statuses',
    });
  },
  methods: {
    formInit() {
      return { read_only: false };
    },
    formSave() {
      return this.submitForm(HTTP, '/master_parameters', {
        success: res => {
          this.$flash('success', `${this.formContext} ${res.data.name} ${this.formSuccessMsg}!`);
        },
      });
    },
    enumFetch,
  },
};
</script>
