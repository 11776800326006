<template>
  <nv-page header="Parents">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-down" />
      <nv-table-search v-model="search" class="mr-0 hidden-xs-only" />
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="parentPath(item.id)"> {{ item.name }}</router-link>
          </td>
          <td>{{ item.country_code && item.country_code.toUpperCase() }}</td>
          <td>{{ item.id }}</td>
        </tr>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Country code', sortable: false, value: 'country_code' },
  { text: 'Tree ID', sortable: false, value: 'id' },
];

export default {
  mixins: [TableMixin],
  data: () => ({
    headers,
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/mv/parents');
    },
    parentPath(parentId) {
      return { name: 'dog.parents.show', params: { parentId } };
    },
  },
};
</script>
