<template>
  <nv-page header="Owners">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-up" />
      <nv-btn v-if="$auth.hasPermissions('dog.owners')" type="add" @click="form.show = true" />
      <v-spacer class="hidden-sm-and-down" />

      <nv-table-search v-model="search" class="mr-0 hidden-xs-only" />
    </template>

    <template slot="filters">
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="ownerPath(item.id)">
              {{ item.name }}
            </router-link>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.country && item.country.name }}</td>
          <td>{{ item.publisher_type | enumLabel(publisherTypes) }}</td>
          <td>{{ item.status | enumLabel(statuses) }}</td>
        </tr>
      </template>
    </v-data-table>
    <edit-form v-model="form.show" @saved="tableFetch" />
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import EditForm from '@/components/owners/EditForm';
import FilterSheet from '@/components/owners/FilterSheet.vue';

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'ID', sortable: false, value: 'id' },
  { text: 'Country', sortable: false, value: 'country' },
  { text: 'Publisher Type', sortable: false, value: 'publisher_type' },
  { text: 'Status', sortable: false, value: 'status' },
];

export default {
  components: { EditForm, FilterSheet },
  mixins: [TableMixin],
  data: () => ({
    headers,
    form: { show: false },
    filters: {},
    statuses: [],
    publisherTypes: [],
  }),
  created() {
    this.enumFetch(HTTP, {
      statuses: '/owners/enum/statuses',
      publisherTypes: '/owners/enum/publisher_types',
    });
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/owners');
    },
    ownerPath(ownerId) {
      return { name: 'dog.owners.show', params: { ownerId } };
    },
    enumFetch,
  },
};
</script>
