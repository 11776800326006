<template>
  <nv-page header="Reports">
    <v-card class="pa-4">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>Report</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Score Card</td>
              <td>A CSV file which contains a list of applications</td>
              <td class="text-md-right">
                <v-btn
                  color="secondary"
                  data-cy="report-scorecard"
                  @click.native.stop="downloadReportScorecard"
                >
                  Download
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';

export default {
  methods: {
    downloadReportScorecard() {
      HTTP.get(`reports/scorecard`).then(res => {
        window.open(res.data.url, '_blank');
      });
    },
  },
};
</script>
