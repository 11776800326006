import vrb from 'bsm-validation-rules';
import { types } from '@/helpers/validations';

export const rules = {
  name: [vrb.presence(), ...types.parameterName],
  parameterType: [vrb.presence(), vrb.typeOf('string')],
  defaultValue: [vrb.presence(), ...types.parameterValue],
  encoding: [vrb.presence(), vrb.typeOf('string')],
  description: [...types.description],
  readOnly: [vrb.presence(), vrb.typeOf('boolean')],
};
