<script>
import { debounce } from 'lodash';
import HTTP from '@/api/dog';
import SubBrandPicker from '@/components/applications/sub_brand_selector/SubBrandPicker.vue';

export default {
  extends: SubBrandPicker,
  data: () => ({
    label: 'Global Sub Brand',
    isDisabled: false,
    selectorType: 'global_autocomplete',
  }),
  methods: {
    fetchItems: debounce(function fetchItems() {
      if (!this.initialized) {
        this.initialized = true;
        return;
      }
      this.isLoading = true;

      HTTP.get(`/ddrs_entities`, {
        params: { search: this.search, per_page: 200 },
      })
        .then(res => {
          this.items = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),
  },
};
</script>
