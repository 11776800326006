<template>
  <nv-page :header="item.name">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn
        v-if="$auth.hasPermissions('dog.application_templates') && item.applications_count === 0"
        ref="edit-button"
        type="edit"
        data-cy="template-edit-btn"
        @click="form.show = true"
      />
    </template>

    <v-card class="pa-4">
      <v-row dense>
        <nv-show cols="12" sm="6" header="Name">{{ item.name }}</nv-show>
        <nv-show cols="12" sm="3" header="ID">{{ item.id }}</nv-show>
        <nv-show cols="12" sm="3" header="Status">{{ item.status | humanize() }}</nv-show>
      </v-row>

      <v-row dense>
        <nv-show cols="12" sm="3" header="Owner Type">
          {{ item.owner_type | enumLabel(ownerTypes) }}
        </nv-show>
        <nv-show cols="12" sm="3" header="Country">
          {{ item.country.name }}
        </nv-show>
        <nv-show cols="12" sm="3" header="JIC Code">
          {{ item.jic_code }}
        </nv-show>
        <nv-show cols="12" sm="3" header="Applications"> {{ item.applications_count }}</nv-show>
      </v-row>

      <v-row dense>
        <nv-show cols="12" sm="3" header="Integration Type">
          {{ item.app_type | enumLabel(appTypes) }}
        </nv-show>
        <nv-show cols="12" sm="3" header="OS">
          {{ item.operating_system | enumLabel(operatingSystems) }}
        </nv-show>
        <nv-show cols="12" sm="3" header="Implementation">
          {{ item.implementation_type | enumLabel(implementationTypes) }}
        </nv-show>

        <nv-show cols="12" sm="6" header="Products" no-placeholder>
          <product-chip-group v-model="item.products" />
        </nv-show>
      </v-row>
    </v-card>

    <v-card class="mt-4 elevation-2">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="parameters-tab">Parameters</v-tab>
        <v-tab data-cy="sdk-configurations-tab">SDK Configurations</v-tab>
        <v-tab data-cy="configuration-tab">Configuration</v-tab>
        <v-tab data-cy="applications-tab">Applications</v-tab>
        <v-tab data-cy="history-tab">History</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item data-cy="parameters-pane">
          <parameters
            ref="parameters"
            :app-count="item.applications_count"
            :template-id="$route.params.templateId"
            @saved="fetchData"
          />
        </v-tab-item>
        <v-tab-item data-cy="sdk-configurations-pane">
          <s-d-k-configurations
            ref="sdkConfigurations"
            :app-count="item.applications_count"
            :template-id="$route.params.templateId"
            @saved="fetchData"
          />
        </v-tab-item>
        <v-tab-item data-cy="configuration-pane">
          <configuration-flags v-model="item" :is-template="true" @input="update()" />
        </v-tab-item>
        <v-tab-item data-cy="applications-pane">
          <applications-tab-list :template-id="$route.params.templateId" />
        </v-tab-item>
        <v-tab-item data-cy="history-pane">
          <history :template-id="$route.params.templateId" />
        </v-tab-item>
      </v-tabs-items>
      <edit-form v-model="form.show" :editing="item" @saved="closeEdit" />
    </v-card>
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import ApplicationsTabList from '@/components/applications/ApplicationsTabList.vue';
import ConfigurationFlags from '@/components/applications/ConfigurationFlags.vue';
import Parameters from '@/components/applications/Parameters.vue';
import SDKConfigurations from '@/components/applications/SDKConfigurations.vue';
import EditForm from '@/components/application_templates/EditForm';
import ProductChipGroup from '@/components/ProductChipGroup.vue';
import History from '@/components/applications/History.vue';

export default {
  components: {
    ApplicationsTabList,
    Parameters,
    SDKConfigurations,
    EditForm,
    ConfigurationFlags,
    ProductChipGroup,
    History,
  },
  data: () => ({
    item: { products: [], country: {} },
    configuration: {},
    form: { show: false },
    tabs: 0,
    appTypes: [],
    implementationTypes: [],
    operatingSystems: [],
    ownerTypes: [],
  }),
  created() {
    this.fetchData();
    this.enumFetch(HTTP, {
      appTypes: '/applications/enum/types',
      implementationTypes: '/applications/enum/implementations',
      operatingSystems: '/applications/enum/operating_systems',
      ownerTypes: '/applications/enum/owners',
    });
  },
  methods: {
    fetchData() {
      HTTP.get(`/applications/templates/${this.$route.params.templateId}`).then(res => {
        this.item = res.data;
        this.originalItem = Object.assign({}, this.item);
      });
    },
    closeEdit() {
      this.$refs.parameters.tableFetch();
      this.fetchData();
    },
    update() {
      if (!this.item.user_opt_out) {
        this.item.user_opt_out = null;
      }
      if (this.item.applications_count === 0) {
        HTTP.put(`/applications/templates/${this.item.id}`, this.item).then(() => {
          this.$flash('success', 'Configuration updated!');
          this.fetchData();
        });
      }
      return false;
    },
    enumFetch,
  },
};
</script>
