<template>
  <v-row dense>
    <v-spacer />
    <v-col class="text-center" cols="12" sm="3">
      <span class="text-caption mr-1">Status:</span>
      <v-btn-toggle v-model="filters.status" data-cy="block-filter-status">
        <v-btn v-for="item in statuses" :key="item.value" :value="item.value" text small>
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
    },
    statuses: [
      {
        text: 'Active',
        value: 'active',
      },
      {
        text: 'Inactive',
        value: 'inactive',
      },
      {
        text: 'All',
        value: 'all',
      },
    ],
  }),
};
</script>
