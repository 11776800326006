<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Master Parameter History</span>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="table"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :items-per-page.sync="table.itemsPerPage"
        must-sort
      >
        <template #item="{ item }">
          <tr>
            <td :title="item.created_at | time">{{ item.created_at | time }}</td>
            <td>{{ item.event | humanize }}</td>
            <td v-placeholder>{{ item.user }}</td>
            <td>
              <ul class="py-4">
                <li v-for="(change, index) in item.changes" :key="index" class="mb-1">
                  <span class="font-weight-bold mr-1">{{ change.attribute | humanize }}:</span>
                  &nbsp;
                  <changelog-item
                    v-if="change.attribute === 'encoding'"
                    :before="change.before | humanize"
                    :after="change.after | humanize"
                  />
                  <changelog-item
                    v-else-if="change.attribute === 'products'"
                    :before="change.before | enumProducts(products)"
                    :after="change.after | enumProducts(products)"
                  />
                  <changelog-item
                    v-else
                    :before="JSON.stringify(change.before)"
                    :after="JSON.stringify(change.after)"
                  />
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="secondary" @click="closeHistoryForm">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { map } from 'lodash';
import ChangelogItem from '@/components/ChangelogItem.vue';

const headers = [
  { text: 'Timestamp', sortable: false, value: 'created_at' },
  { text: 'Event', sortable: false, value: 'event' },
  { text: 'User', sortable: false, value: 'user' },
  { text: 'Changes', sortable: false, value: 'changes' },
];

export default {
  filters: {
    enumProducts(input, enums) {
      if (!enums) return null;
      return map(input, i => {
        const o = enums.find(e => e.code === i) || {};
        return o.name;
      }).join(',');
    },
  },
  components: { ChangelogItem },
  mixins: [TableMixin],
  props: { parameterId: { type: Number, default: null } },
  data: () => ({
    headers,
    products: [],
    tableParam: 'history.dt',
    table: { itemsPerPage: 20 },
  }),
  created() {
    this.enumFetch(HTTP, {
      products: '/applications/enum/products',
    });
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/master_parameters/${this.parameterId}/history`);
    },
    closeHistoryForm() {
      this.$emit('close', false);
    },
    tableWatchHandler() {
      this.tableFetch();
    },
    enumFetch,
  },
};
</script>
