<template>
  <div>
    <v-text-field
      :value="clientLabel"
      :loading="isLoading"
      data-cy="sub_brand_label"
      readonly
      flat
      clearable
      label="Sub-brand"
      @click:clear="clear()"
      @click="showDialog()"
    />
    <sub-brand-selector-dialog
      v-model="dialog"
      :ddrs-entity="globalItem"
      :local-entity="localItem"
      :selector-form-type="selectorFormType"
      @ddrs-entity="updateDDRSEntity($event)"
      @local-entity="updateLocalDDRSEntity($event)"
    />
  </div>
</template>

<script>
import HTTP from '@/api/dog';
import SubBrandSelectorDialog from '@/components/applications/sub_brand_selector/SubBrandSelectorDialog.vue';

export default {
  components: { SubBrandSelectorDialog },
  props: {
    value: { type: Number, default: null },
    localEntity: { type: Number, default: null },
    formType: { type: String, default: 'new' },
  },
  data: () => ({
    dialog: false,
    globalItem: {},
    localItem: {},
    isLoading: false,
    selectorFormType: '',
  }),
  computed: {
    clientLabel() {
      let labelItem;
      if (this.localItem && this.localItem.id > 0) {
        labelItem = this.localItem;
      } else if (this.globalItem && this.globalItem.id > 0) {
        labelItem = this.globalItem;
      }

      if (labelItem && labelItem.id > 0) {
        let label = `${labelItem.name} (${labelItem.client_id} / ${labelItem.vc_id})`;
        if (labelItem.parent) label = `${labelItem.parent} / ${label}`;
        return label;
      }
      return '';
    },
  },
  watch: {
    value(cur) {
      if (cur) {
        this.fetchSubBrand(cur, 'global');
      }
    },
    localEntity(cur) {
      if (cur) {
        this.fetchSubBrand(cur, 'local');
      }
    },
  },
  methods: {
    clear() {
      this.$emit('input', null);
      this.subBrand = 0;
      this.updateDDRSEntity(0);
      this.updateLocalDDRSEntity(0);
    },
    showDialog() {
      this.selectorFormType = this.formType;
      if (this.selectorFormType === 'new') {
        this.clear();
      }
      this.dialog = true;
    },
    updateDDRSEntity(event) {
      this.selectorFormType = 'selected';
      if (event === 0 || event == null) {
        this.globalItem = {};
        this.$emit('input', null);
        return;
      }

      this.fetchSubBrand(event, 'global');
      this.$emit('input', event);
    },
    updateLocalDDRSEntity(event) {
      if (event === 0 || event == null) {
        this.localItem = {};
        this.$emit('local-input', null);
        return;
      }

      this.fetchSubBrand(event, 'local');
      this.$emit('local-input', event);
    },
    fetchSubBrand(id, context) {
      this.isLoading = true;
      HTTP.get(`/ddrs_entities/${id}`)
        .then(res => {
          switch (context) {
            case 'global':
              this.globalItem = res.data;
              break;
            case 'local':
              this.localItem = res.data;
              break;
            default:
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
