<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import ApplicationsFilterSheet from '@/components/applications/ApplicationsFilterSheet';
import store from 'store';

const fns = store.namespace('filters');
const defaultFilters = {
  status: 'active',
  product: null,
  jic_code: null,
  country_code: null,
  operating_system: null,
  owner_type: null,
  syndicated: null,
};

export default {
  extends: ApplicationsFilterSheet,
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      ...defaultFilters,
      ...fns.get('templates'),
    },
  }),
  methods: {
    clearFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = defaultFilters[key];
      });
    },
  },
};
</script>
