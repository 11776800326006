<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td :title="item.created_at | time('long')">{{ item.created_at | time('long') }}</td>
          <td>{{ item.event | humanize }}</td>
          <td v-placeholder>{{ item.user }}</td>
          <td v-placeholder>
            {{ item.context.type | humanize }}
            <span v-if="item.context.name">
              <kbd>{{ item.context.name }}</kbd>
            </span>
          </td>
          <td>
            <ul class="py-4">
              <li v-for="(change, index) in item.changes" :key="index" class="mb-1">
                <span class="font-weight-bold mr-1">{{ change.attribute | humanize }}:</span>
                &nbsp;
                <changelog-item
                  v-if="change.attribute === 'encoding'"
                  :before="change.before | humanize"
                  :after="change.after | humanize"
                />
                <changelog-item
                  v-else
                  :type="change.attribute"
                  :before="JSON.stringify(change.before)"
                  :after="JSON.stringify(change.after)"
                />
              </li>
            </ul>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import ChangelogItem from '@/components/ChangelogItem.vue';

const headers = [
  { text: 'Timestamp', sortable: false, value: 'created_at' },
  { text: 'Event', sortable: false, value: 'event' },
  { text: 'User', sortable: false, value: 'user' },
  { text: 'Context', sortable: false, value: 'context' },
  { text: 'Changes', sortable: false, value: 'changes' },
];

export default {
  components: { ChangelogItem },
  mixins: [TableMixin],
  props: {
    applicationId: { type: String, default: null },
    templateId: { type: [Number, String], default: null },
  },
  data: () => ({
    headers,
    tableParam: 'history.dt',
  }),
  methods: {
    tableFetch() {
      if (this.applicationId) {
        this.paginateTableData(HTTP, `/applications/${this.applicationId}/history`);
      } else {
        this.paginateTableData(HTTP, `/applications/templates/${this.templateId}/history`);
      }
    },
  },
};
</script>
