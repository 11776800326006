<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      title="Duplicate Application"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <name-selector ref="name-selector" v-model="item.name" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            ref="description"
            v-model="item.description"
            :rules="rules.description"
            label="Description"
            hint="The application description"
            counter="255"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.website_url"
            :rules="rules.websiteURL"
            label="Website URL"
            hint="The application Website URL"
            counter="255"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.support_url"
            :rules="rules.supportURL"
            label="Support URL"
            hint="The application Support URL"
            counter="255"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.store_url"
            :rules="rules.storeURL"
            label="Store URL"
            hint="The application Store URL"
            counter="255"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';
import NameSelector from '@/components/applications/NameSelector.vue';

export default {
  components: { NameSelector },
  mixins: [FormMixin],
  props: {
    applicationId: { type: String, required: true },
    appType: { type: String, required: true },
    countryCode: { type: String, default: null },
  },
  data: () => ({
    rules,
  }),
  methods: {
    formInit() {
      return {
        country_code: this.countryCode,
        app_type: this.appType,
      };
    },
    formSave() {
      return this.submitForm(HTTP, `/applications/${this.applicationId}/clone`, {
        success: res => {
          this.$flash('success', 'Application duplicate edited!');
          this.$router.push({
            name: 'dog.applications.show',
            params: { applicationId: res.data.id },
          });
        },
      });
    },
  },
};
</script>
