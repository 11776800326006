<template>
  <div>
    <nv-show v-if="displayType === 'show'" header="Client ID/Sub-brand">
      <span v-if="value.mocr_entity">
        {{ value.mocr_entity.parent_name + ' / ' + value.mocr_entity.name }}
      </span>
      <span v-if="value.ddrs_entity">
        <router-link :to="ddrsPath(ddrsEntityId)">
          {{ clientLabel }}
        </router-link>
        <v-icon v-if="icon" @click="showDetails()">{{ icon }}</v-icon>
        <br />
        <span class="text-caption">
          <router-link :to="ddrsPath(ddrsEntityId)">
            {{ clientCaption }}
          </router-link>
        </span>
      </span>
    </nv-show>

    <span v-else-if="displayType === 'table'">
      <router-link v-if="value.ddrs_entity" :to="ddrsPath(ddrsEntityId)">
        {{ value.ddrs_entity && value.ddrs_entity.client_id }}
      </router-link>
      <v-icon v-if="icon" small @click="showDetails()">{{ icon }}</v-icon>
      <br />
      <router-link v-if="value.ddrs_entity" :to="ddrsPath(ddrsEntityId)">
        {{ labelItem && labelItem.vc_id }}
      </router-link>
    </span>

    <v-dialog v-model="globalDialog" max-width="300px">
      <v-card>
        <v-card-title class="text-h5" primary-title>Global DDRS Entity</v-card-title>
        <v-card-text><client-show v-model="globalEntity"/></v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="localDialog" max-width="480px">
      <v-card>
        <v-card-title class="text-h5" primary-title>Local DDRS Entities</v-card-title>
        <v-card-text class="pt-0">
          <v-container v-if="localItems.length > 0" class="pa-0">
            <table class="v-datatable v-table theme--dark">
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Code</th>
                  <th class="text-left">Country</th>
                  <th class="text-left eff-date">Effective date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in localItems" :key="item.id">
                  <td>
                    <router-link :to="ddrsPath(item.id)">
                      {{ item.name }}
                    </router-link>
                  </td>
                  <td>{{ item.vc_id }}</td>
                  <td>{{ item.country_code | uppercase }}</td>
                  <td
                    :title="item.effective_date && item.effective_date | date('long')"
                    class="eff-date"
                  >
                    {{ item.effective_date && item.effective_date | date }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
          <v-container v-else class="flex">
            <span>No localized clients</span>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HTTP from '@/api/dog';

export default {
  name: 'ClientShow',
  props: {
    value: { type: Object, default: () => ({}) },
    displayType: { type: String, default: 'show' },
  },
  data: () => ({
    globalDialog: false,
    localDialog: false,
    localItems: [],
  }),
  computed: {
    isLocal() {
      if (this.value.ddrs_entity_local) {
        return true;
      } else if (this.value.ddrs_entity) {
        return false;
      }
      // return null to support MOCR case
      return null;
    },
    ddrsEntityId() {
      if (this.value.ddrs_entity_local) {
        return this.value.ddrs_entity_local.id;
      }

      if (this.value.ddrs_entity) {
        return this.value.ddrs_entity.id;
      }
      // return null to support MOCR case
      return null;
    },
    globalEntity() {
      return { ddrs_entity: this.value.ddrs_entity };
    },
    labelItem() {
      if (this.isLocal) {
        return this.value.ddrs_entity_local;
      } else if (this.value.ddrs_entity) {
        return this.value.ddrs_entity;
      }
      return null;
    },
    icon() {
      if (this.isLocal) {
        return 'mdi-map-marker';
      } else if (this.isLocal === false && this.value.ddrs_entity.active_local_ddrs_count > 0) {
        return 'mdi-earth';
      }
      return null;
    },
    clientLabel() {
      if (this.labelItem && this.labelItem.id > 0) {
        return `${this.value.ddrs_entity.parent} / ${this.labelItem.name}`;
      }
      return '';
    },
    clientCaption() {
      if (this.labelItem && this.labelItem.id > 0) {
        return `${this.value.ddrs_entity.client_id} / ${this.labelItem.vc_id}`;
      }
      return '';
    },
  },
  methods: {
    showDetails() {
      if (this.isLocal) {
        this.globalDialog = true;
      } else {
        this.fetchLocalEntities();
        this.localDialog = true;
      }
    },
    ddrsPath(ddrsId) {
      return {
        name: 'dog.ddrs_entities.show',
        params: { ddrsId },
      };
    },
    fetchLocalEntities() {
      this.localItems = [];

      this.isLoading = true;

      HTTP.get(`/ddrs_entities/${this.value.ddrs_entity.id}/local_entities`, {
        params: { search: this.search, per_page: 200 },
      })
        .then(res => {
          this.localItems = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
table th.eff-date,
table td.eff-date {
  min-width: 140px;
}
</style>
