<template>
  <nv-page header="VCIDs">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-down" />

      <nv-table-search v-model="search" class="mr-0 hidden-xs-only" />
    </template>

    <template slot="filters">
      <nv-table-filters
        ref="applied-filters"
        :value="formattedFilters"
        :no-clear="formattedFilters.status === 'all'"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.parent }}</td>
          <td>
            <router-link :to="ddrsPath(item.id)">{{ item.name }}</router-link>
          </td>
          <td>{{ item.client_id }}</td>
          <td>{{ item.vc_id }}</td>
          <td>{{ item.level | humanize }}</td>
          <td>{{ item.country_code | uppercase }}</td>
          <td :title="item.effective_date | date">
            {{ item.effective_date | date }}
          </td>
          <td>{{ item.status | humanize }}</td>
        </tr>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/ddrs_entities/FilterSheet.vue';

const headers = [
  { text: 'Parent', sortable: true, value: 'parent' },
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Client ID', sortable: false, value: 'client_id' },
  { text: 'VCID', sortable: false, value: 'vc_id' },
  { text: 'Level', sortable: false, value: 'level' },
  { text: 'Country code', sortable: false, value: 'country_code' },
  { text: 'Effective date', sortable: false, value: 'effective_date' },
  { text: 'Status', sortable: false, value: 'status' },
];

export default {
  components: { FilterSheet },
  mixins: [TableMixin],
  data: () => ({
    headers,
  }),
  computed: {
    formattedFilters() {
      return { status: this.tableFilters.status || 'all' };
    },
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/ddrs_entities');
    },
    ddrsPath(ddrsId) {
      return { name: 'dog.ddrs_entities.show', params: { ddrsId } };
    },
    setTableFilters(filters) {
      if (filters.status == null || filters.status === 'all') {
        this.tableFilters = {};
      } else {
        this.tableFilters = { ...filters };
      }
    },
  },
};
</script>
