<script>
import { debounce } from 'lodash';
import HTTP from '@/api/dog';
import SubBrandPicker from '@/components/applications/sub_brand_selector/SubBrandPicker.vue';

export default {
  extends: SubBrandPicker,
  props: {
    globalSubBrand: { type: Number, default: 0 },
  },
  data: () => ({
    label: 'Local Sub Brand (Optional)',
    selectorType: 'local_autocomplete',
  }),
  computed: {
    isDisabled() {
      return this.globalSubBrand === 0;
    },
  },
  watch: {
    globalSubBrand() {
      this.initialized = true;
      this.fetchItems();
    },
  },
  created() {
    if (this.value.id) {
      this.items = [this.value];
      this.isLoading = false;
    } else {
      this.initialized = true;
    }
  },
  methods: {
    fetchItems: debounce(function fetchItems() {
      if (!this.initialized) {
        this.initialized = true;
        return;
      }

      this.items = [];
      if (this.globalSubBrand === 0) {
        return;
      }

      this.isLoading = true;

      HTTP.get(`/ddrs_entities/${this.globalSubBrand}/local_entities`, {
        params: { search: this.search, per_page: 200 },
      })
        .then(res => {
          this.items = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),
  },
};
</script>
