<template>
  <div>
    <input ref="fileInput" type="file" style="display: none;" @change="fileChanged" />
    <v-btn v-if="!fileName" color="secondary" class="mx-0" @click="selectFile">
      Upload
      <v-icon right dark>mdi-cloud-upload</v-icon>
    </v-btn>
    <v-btn v-if="fileName" color="primary" class="ml-0" @click="selectFile">
      Selected
      <v-icon right dark>mdi-cloud-check</v-icon>
    </v-btn>
    <v-chip v-if="fileChip" close class="ml-4" @click:close="resetFile">{{ fileName }}</v-chip>
  </div>
</template>

<script>
export default {
  name: 'UploadButton',
  props: {
    value: {
      type: File,
      default: null,
    },
  },
  data: () => ({
    fileName: null,
  }),
  computed: {
    fileChip() {
      return !!this.fileName;
    },
  },
  methods: {
    resetFile() {
      this.fileName = null;
      this.$refs.fileInput.value = null;
      this.$emit('input', null);
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    fileChanged(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;
        this.$emit('input', file);
      }
    },
  },
};
</script>
