<template>
  <nv-page :header="item.name">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn v-if="$auth.hasPermissions('dog.owners')" type="edit" @click="form.show = true" />
    </template>

    <v-card class="pa-4">
      <v-row dense>
        <nv-show cols="12" sm="3" header="ID">{{ item.id }}</nv-show>
        <nv-show cols="12" sm="9" header="Name">{{ item.name }}</nv-show>

        <nv-show cols="12" sm="3" header="Status">
          {{ item.status | enumLabel(ownerStatuses) }}
        </nv-show>
        <nv-show cols="12" sm="3" header="Country">
          {{ item.country && item.country.name }}
        </nv-show>
        <nv-show cols="12" sm="6" header="Publisher Type">
          {{ item.publisher_type | enumLabel(publisherTypes) }}
        </nv-show>
      </v-row>
    </v-card>

    <v-card class="mt-4 elevation-2">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab>Applications</v-tab>
        <v-tab data-cy="history-tab">History</v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id" v-model="tabs">
        <v-tab-item data-cy="applications-pane">
          <applications-tab-list
            ref="apps_tab"
            :owner-id="item.id"
            :owner-active="item.status === 'active'"
          />
        </v-tab-item>
        <v-tab-item data-cy="history-pane">
          <history ref="history_tab" :owner-id="item.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <edit-form v-model="form.show" :editing="item" @saved="fetchData" />
  </nv-page>
</template>

<script>
import HTTP from '@/api/dog';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import ApplicationsTabList from '@/components/applications/ApplicationsTabList.vue';
import EditForm from '@/components/owners/EditForm.vue';
import History from '@/components/owners/History.vue';

export default {
  components: { ApplicationsTabList, EditForm, History },
  data: () => ({
    form: { show: false },
    tabs: 0,
    item: { status: 'inactive' },
    ownerStatuses: [],
    publisherTypes: [],
  }),
  created() {
    this.fetchData();
    this.enumFetch(HTTP, {
      ownerStatuses: '/owners/enum/statuses',
      publisherTypes: '/owners/enum/publisher_types',
    });
  },
  methods: {
    fetchData() {
      HTTP.get(`/owners/${this.$route.params.ownerId}`).then(res => {
        this.item = res.data;
      });
    },
    enumFetch,
  },
};
</script>
