<template>
  <v-dialog :value="value" persistent max-width="1200px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="new-template-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <template-selector
        ref="template-selector"
        v-model="item.application_template_id"
        @input="fetchTemplate()"
      />

      <v-row dense>
        <v-col sm="6" cols="12">
          <name-selector ref="name-selector" v-model="item.name" />
        </v-col>

        <v-col sm="6" cols="12">
          <v-text-field
            v-model="item.description"
            :rules="rules.description"
            label="Description"
            hint="The application description"
            counter="255"
          />
        </v-col>

        <v-col sm="4" cols="12">
          <nv-show v-if="ownerId" header="Owner">
            {{ owner && owner.name }}
          </nv-show>
          <owner-search v-else v-model="item.owner_id" />
        </v-col>

        <v-col sm="4" cols="12">
          <sub-brand-selector v-model="item.ddrs_entity_id" />
        </v-col>

        <v-col cols="12" sm="4">
          <v-select
            ref="deploymentType"
            v-model="item.deployment_type"
            :rules="rules.deploymentType"
            :items="enumDeploymentTypes"
            label="Deployment"
            required
            multi-line
          />
        </v-col>

        <nv-show cols="12" sm="4" header="JIC Code">
          {{ template.jic_code }}
        </nv-show>

        <nv-show cols="12" sm="4" header="Ownership">
          {{ template.owner_type | enumLabel(enumOwnerTypes) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="Integration Type">
          {{ template.app_type | enumLabel(enumAppTypes) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="OS">
          {{ template.operating_system | enumLabel(enumOperatingSystems) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="Implementation">
          {{ template.implementation_type | enumLabel(enumImplementationTypes) }}
        </nv-show>

        <nv-show cols="12" sm="4" header="Products" no-placeholder>
          <product-chip-group v-model="template.products" />
        </nv-show>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.website_url"
            :rules="rules.websiteURL"
            label="Website URL"
            hint="The website URL"
            counter="255"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.support_url"
            :rules="rules.supportURL"
            label="Support URL"
            hint="The support URL"
            counter="255"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field
            v-model="item.store_url"
            :rules="rules.storeURL"
            label="Store URL"
            hint="The store URL"
            counter="255"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/dog';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';
import OwnerSearch from '@/components/applications/OwnerSearch.vue';
import SubBrandSelector from '@/components/applications/SubBrandSelector.vue';
import NameSelector from '@/components/applications/NameSelector.vue';
import TemplateSelector from '@/components/applications/TemplateSelector.vue';
import ProductChipGroup from '@/components/ProductChipGroup.vue';

export default {
  components: {
    OwnerSearch,
    ProductChipGroup,
    SubBrandSelector,
    NameSelector,
    TemplateSelector,
  },
  mixins: [FormMixin],
  props: { ownerId: { type: Number, default: null } },
  data: () => ({
    rules,
    formContext: 'Application',
    owner: {},
    template: { products: [], suspended_products: [], app_type: '' },
    enumStatuses: [],
    enumOperatingSystems: [],
    enumImplementationTypes: [],
    enumOwnerTypes: [],
    enumAppTypes: [],
    enumDeploymentTypes: [],
  }),
  computed: {
    filteredTemplates() {
      return this.templates.filter(t => t.country_code === this.item.country_code);
    },
  },
  created() {
    this.fetchData();
    this.enumFetch(HTTP, {
      countries: '/countries?per_page=300&filter[status]=active',
      enumStatuses: '/applications/enum/statuses',
      enumOperatingSystems: '/applications/enum/operating_systems',
      enumImplementationTypes: '/applications/enum/implementations',
      enumOwnerTypes: '/applications/enum/owners',
      enumAppTypes: '/applications/enum/types',
      enumDeploymentTypes: '/applications/enum/deployment_types',
    });
  },
  methods: {
    fetchData() {
      if (this.ownerId) {
        HTTP.get(`/owners/${this.ownerId}`).then(res => {
          this.owner = res.data;
        });
      }
      HTTP.get('/applications/templates', { params: { per_page: 300 } }).then(res => {
        this.templates = res.data;
      });
    },
    fetchTemplate() {
      if (!this.item.application_template_id) return;
      HTTP.get(`/applications/templates/${this.item.application_template_id}`).then(res => {
        this.template = res.data;
      });
    },
    formSave() {
      const url = this.ownerId
        ? `/owners/${this.ownerId}/applications`
        : `/owners/${this.item.owner_id}/applications`;
      return this.submitForm(HTTP, url, {
        success: res => {
          this.$flash('success', `Application ${this.formSuccessMsg}!`);
          this.$router.push({
            name: 'dog.applications.show',
            params: { applicationId: res.data.id },
          });
        },
      });
    },
    enumFetch,
  },
};
</script>

<style scoped>
.v-card__title {
  padding-bottom: 0;
}
.v-card__text {
  padding: 0;
}
.v-card__actions {
  padding-top: 0;
}
.container {
  padding-top: 12px;
  padding-bottom: 24px;
}
</style>
