/* eslint-disable no-useless-escape */
import vrb from 'bsm-validation-rules';

export const types = {
  string: function(maxLength) {
    return [vrb.typeOf('string'), vrb.length({ max: maxLength })];
  },
  countryCode: [
    vrb.typeOf('string'),
    vrb.format(/^[A-Za-z]{2}$/, 'must be valid country code format'), // TODO! not obvious from API if codes are upper or lowercase!
  ],
  name: [
    vrb.typeOf('string'),
    vrb.length({ max: 255 }),
    vrb.format(/^[a-zA-Z0-9(]([^\t\n\b]*[a-zA-Z0-9*.!)])?$/, 'must be valid name format'),
  ],
  description: [
    vrb.typeOf('string'),
    vrb.length({ max: 255 }),
    vrb.format(/^[a-zA-Z0-9(]([^\t\n\b]*[a-zA-Z0-9*.!)#])?$/, 'must be valid description format'),
  ],
  url: [
    vrb.typeOf('string'),
    vrb.length({ max: 255 }),
    vrb.format(/^https?:\/\/.+$/, 'must be valid URL format'),
  ],
  jicCode: [
    vrb.typeOf('string'),
    vrb.length({ max: 4 }),
    vrb.format(/^[A-Z]+$/, 'must be valid JIC code format'),
  ],
  parameterName: [
    vrb.typeOf('string'),
    vrb.length({ max: 128 }),
    vrb.format(/^[A-Za-z0-9]+(_[A-Za-z0-9]+)*?$/, 'must be valid name format'),
  ],
  parameterValue: [
    vrb.typeOf('string'),
    vrb.length({ max: 255 }),
    vrb.format(/^[\[\{]?[A-Za-z0-9_\-\.:+]+[\]\}]?$/, 'must be valid value format'),
  ],
  range: function(min, max) {
    return [vrb.numericality({ min: min, max: max })];
  },
};
